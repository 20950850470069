.approach-info {
  .images-container {
    img {
      width: 200px;
      height: 150px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 821px) {
  .images-container {
    img {
      min-width: 70%;
      height: 150px;
      margin: 1rem;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
