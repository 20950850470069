.quote-container {
  background-image: url("../../../../../Asset/QuoteImg/SidhabaliImages/Shree\ Sidhbali..jpg");
  width: 100%;
  min-height: 55.6rem;
  max-height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .form-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 6rem;
    .form-group {
      position: relative;
      margin-bottom: 20px;
      input {
        width: 50%;
        background-color: black;
        border: 0;
        height: 6rem;
        padding: 8px 15px;
        border-radius: 10px;
        color: #6e6e6e;
        letter-spacing: 0.1px;
        font-size: 14px;
        appearance: auto;
        -webkit-appearance: auto;
        padding: 2rem;
      }
      .message-input {
        width: 100%;
      }
    }
  }
  .img-container {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 5rem;
    overflow: hidden;
    img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      transition: 0.3s;
      max-width: 90%;
      width: 255px;
      height: auto;
      margin: 0 auto 3rem;
      overflow: hidden;
    }
  }
  .form-group {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
  .form-row {
    padding: 4rem;
  }
  .form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    align-items: end;
  }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .form-group {
    position: relative;
    margin-bottom: 20px;
  }
  .form-group i {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #e8e8e8;
  }
  .custom-form .form-group i {
    color: #fff;
    height: 100%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    border-left: 2px solid #383838;
  }
  textarea.form-control {
    height: auto;
  }

  .form-control::-webkit-input-placeholder {
    color: white;
  }

  .form-control::-moz-placeholder {
    color: white;
  }

  .form-control:-ms-input-placeholder {
    color: white;
  }

  .form-control::-ms-input-placeholder {
    color: white;
  }

  .form-control::placeholder {
    color: white;
  }

  .form-control {
    height: 60px;
    padding: 8px 15px;
    border-radius: 10px;
    border: 2px solid #efefef;
    width: 100%;
    color: #0e0000;

    letter-spacing: 0.1px;
    font-size: 14px;
    appearance: auto;
    -webkit-appearance: auto;
  }

  .form-control.transparent {
    background-color: rgb(196 5 5 / 32%);
    border: 0;
  }
  .form-control.transparent:focus {
    background-color: rgb(196 5 5 / 32%);
    border: 0;
  }

  .form-control.dark {
    border: 0;
  }
  .form-control.dark:focus {
    border: 0;
  }

  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #efefef;
  }
  .sidebar-widget .sigma_search-adv-input .form-control {
    color: #6e6e6e;
  }
  .sidebar-widget .sigma_search-adv-input .form-control::placeholder {
    color: #0e0000;
  }
  .sigma_search-adv-input .form-control::placeholder {
    color: #fff;
  }

  .form-group {
    position: relative;
    margin-bottom: 20px;
  }
  .error_message {
    color: white;
    background-color: #db4242;
    border-radius: 5rem;
    font-size: 1.5rem;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 0 1rem;
    position: relative;
    top: 1rem;
  }
  .form-group i {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #e8e8e8;
  }
  .form-control.transparent {
    background-color: #87cfeb97 !important;
    border: 0;
  }
  .form-control.transparent:focus {
    background-color: #87cfeb97 !important;
    border: 0;
  }
  .sigma_btn-custom.transparent {
    background-color: transparent;
    border: 1px solid transparent;
    color: #db4242;
  }
  .sigma_btn-custom.transparent::before {
    background-color: #ec6a47;
  }
  .sigma_btn-custom.transparent:hover,
  .sigma_btn-custom.transparent:focus {
    color: #fff;
    border: 1px solid #efefef;
  }
  .col-lg-12 {
    width: 100%;
  }
  .sigma_btn-custom {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    background-color: #7e4555;
    border: 0;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 15px 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border-radius: 30px;
    font-weight: 600;
    overflow: hidden;
    z-index: 1;
  }

  .sigma_btn-custom::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    background-color: #d43308;
    transform: scaleY(0);
    transform-origin: 50%;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  .sigma_btn-custom:focus::before,
  .sigma_btn-custom:hover::before {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }

  .sigma_btn-custom:hover,
  .sigma_btn-custom:focus {
    color: #fff;
    outline: none;
  }
  .sigma_btn-custom.primary {
    background-color: #7e4555;
  }

  .sigma_btn-custom.transparent {
    background-color: transparent;
    border: 1px solid transparent;
    color: #db4242;
  }
  .sigma_btn-custom.transparent::before {
    background-color: #ec6a47;
  }
  .sigma_btn-custom.transparent:hover,
  .sigma_btn-custom.transparent:focus {
    color: #fff;
    border: 1px solid #efefef;
  }

  .sigma_btn-custom.primary::before {
    background-color: #ec6a47;
  }

  .sigma_btn-custom.dark {
    background-color: #db4242;
    color: #fff;
    box-shadow: none;
  }
  .sigma_btn-custom.dark::before {
    background-color: #65303f;
  }
  .sigma_btn-custom.light:hover,
  .sigma_btn-custom.light:focus,
  .sigma_btn-custom.dark:hover,
  .sigma_btn-custom.dark:focus {
    color: #fff;
  }
  .sigma_btn-custom.light {
    background-color: transparent;
    color: #db4242;
    box-shadow: none;
    border: 2px solid #efefef;
  }

  .sigma_btn-custom.secondary {
    background-color: #db4242;
    color: #fff;
  }

  .sigma_btn-custom.secondary::before {
    background-color: #65303f;
  }

  .sigma_btn-custom.btn-sm {
    padding: 12px 26px;
    font-size: 14px;
  }
  .sigma_btn-custom.btn-pill {
    border-radius: 55px;
  }

  .sigma_btn-custom i {
    margin-left: 10px;
  }

  .btn-group .sigma_btn-custom + .sigma_btn-custom {
    padding: 12px 14px;
  }

  .btn-group .sigma_btn-custom.btn-sm + .sigma_btn-custom.btn-sm {
    padding: 12px 16px;
  }

  .input-group-prepend,
  .input-group-append {
    margin: 0;
    display: flex;
  }

  .input-group-prepend .sigma_btn-custom,
  .input-group-prepend .sigma_btn-custom::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .input-group-append .sigma_btn-custom,
  .input-group-append .sigma_btn-custom::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .btn-group
    .sigma_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after {
    animation-name: BounceUpDown;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
  }

  .btn-group.dropup
    .sigma_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after {
    animation-name: BounceDownUp;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
  }
  textarea {
    resize: none;
  }
}
.quote_modal {
  .modal-content {
    max-height: 4rem;
    min-width: 10rem;

    .alert-info {
      font-size: large;
      width: 20rem;
    }
  }
}
@media (max-width: 991px) {
  .quote-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    flex-direction: column;
    padding: 4rem !important;
    background-repeat: no-repeat;
    background-size: cover;
    .col-lg-6 {
      width: 100%;
    }
    .form-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 6rem;
      .form-group {
        input {
          width: 50%;
          background-color: rgba(255, 255, 255, 0.1);
          border: 0;
          height: 60px;
          padding: 8px 15px;
          border-radius: 10px;
          color: #6e6e6e;
          letter-spacing: 0.1px;
          font-size: 14px;
          appearance: auto;
          -webkit-appearance: auto;
        }
        .message-input {
          width: 100%;
        }
      }
    }
    .img-container {
      flex: 0 0 auto;
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 5rem;
      img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        transition: 0.3s;
        max-width: 90%;
        width: 255px;
        height: auto;
        margin: 0 auto 3rem;
      }
    }
    .form-group {
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
    .form-row {
      display: contents;
      width: 100%;
    }
  }
  .mb-lg-30,
  .sigma_upsells {
    margin-bottom: 3rem;
  }
}
