.menu-item-container {
  position: relative;

  z-index: 999;
  & > .menu-item {
    position: relative;
    & {
      .sub-menu {
        position: relative;
        background-color: rgb(255, 255, 255);
        top: 1rem;
        left: -2rem;
        & ul {
          text-align: left;
          width: 10rem;
          position: absolute;
          background-color: white;
          left: 13rem;
          top: -1rem;
          width: 40rem;
          height: fit-content;
          &::before {
            content: "";
          }
        }
      }
    }
    &:hover {
      position: sticky;
      color: #7e4555;
      & .sub-menu {
        display: block;
        width: 15rem;
      }
    }
    & > a {
      color: #000000;
      // padding: 30px 15px;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      text-decoration: none;
      display: flex;
      align-items: center;
      & {
        span {
          margin-right: 0.3rem;
        }
      }
      // &::before {
      //   // content: "";
      //   width: 100%;
      //   height: 0;
      //   transition: 0.3s;
      //   background-color: #fff;
      // }
    }
    & .sub-menu {
      display: none;
      & li {
        margin: 0;
        & > a {
          text-decoration: none;
          padding: 15px 20px;
          color: #777;
          font-size: 13px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:hover {
            color: #7e4555;
            transform: translateX(5px);
          }
        }
      }
      &:hover {
        & .sub-menu {
          display: block;
        }
      }
    }
  }
  & .menu-item.menu-item-has-children > a::after {
    font-family: "Font Awesome 5 Pro";
    // content: "\f067";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1.7;
    margin-left: auto;
    font-size: 10px;
  }

  & .sub-menu .menu-item-has-children {
    position: relative;

    &:hover {
      top: 100%;
      opacity: 1;
      visibility: visible;
    }
    // & > a::after {
    //   // content: "\f054";
    // }
    & {
      .menu-item {
        display: none;
      }
      &:hover {
        .menu-item {
          display: block;
        }
      }
    }
  }
}
// position: relative;
//           left: 11rem;
//           bottom: 3rem;
//           background-color: white;
