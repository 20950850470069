.home1-container {
  //  background-image: url("https://i0.wp.com/wordzz.com/wp-content/uploads/2020/12/Lord-Krishna-Flute-Full-Moon.jpg?resize=1052%2C1440&ssl=1");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-position: center;
  background-size: cover;
  flex-direction: row-reverse;
  .mobile_header {
    display: none;
    margin: 2rem auto;

    img {
      width: 50rem;
    }
  }
  img {
    width: 100%;
  }
  .head-content {
    width: 30%;
    flex: 1 0 auto;
    // padding: 0 1.5rem;

    margin: 5rem;
    padding: 5rem;
    .title {
      color: #db4242;
      font-weight: 400;
      font-size: 30px;
    }
    .blockquote {
      // color: #03a9f4;
      color: white;
      margin: 20px 0;
      font-size: 14px;
      padding: 10px 30px;
      background-position: center;
      background-size: cover;
      border-left: 2px solid #7e4555;
      background-color: transparent !important;
      max-width: 600px;
      p {
        line-height: 1.9;
      }
    }
    .section-button {
      & button {
        margin-top: 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        position: relative;
        background-color: #7e4555;
        border: 0;
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        padding: 15px 30px;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        border-radius: 30px;
        font-weight: 600;
        overflow: hidden;
        z-index: 1;
        margin: 0.5rem;
        &:hover {
          transform: scaleY(1);
          transition: width 2s, height 4s;
          opacity: 0.8;
          background-color: #7e4555ec;
          visibility: visible;
        }
      }
      .white {
        background-color: #fff;
        color: #db4242;
        box-shadow: none;
        &:hover {
          transform: scaleY(1);
          transition: width 2s, height 4s;
          opacity: 0.8;
          background-color: #ffffffec;
          visibility: visible;
        }
      }
    }
  }
  //Banner

  .blockquote {
    max-width: 500px;
    position: relative;
    margin: 20px 0;
    font-size: 14px;
    color: #db4242;
    padding: 10px 30px;
    background-position: center;
    background-size: cover;
    background-color: #f7f7f7;
    border-left: 2px solid #7e4555;
  }
  p.blockquote:before,
  p.blockquote:after {
    content: "";
    position: absolute;
    left: -9px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 30px;
    background-color: #7e4555;
  }
  p.blockquote:after {
    left: 6px;
  }
  .bg-transparent {
    color: #020202;
  }

  //Banner
}
.empty-content {
  width: 50%;
  flex: 0 0 auto;
  padding: 0 1.5rem;
  margin: 4rem;
}

@media screen and (max-width: 821px) {
  .empty-content {
    width: 0;
    padding: 0rem;
    margin: 0rem;
  }
  .home1-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .head-content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      display: none;
      .title {
        color: #db4242;
        font-weight: 400;
        font-size: 30px;
      }
    }
    .mobile_header {
      display: block;
      margin: 0rem 10rem auto;

      img {
        width: 50rem;
      }
    }
  }
}
