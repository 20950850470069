.puja-time-container {
  background-image: url("../../../../../../../Asset/Arti/arti.png");
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
  background-size: cover;

  margin: 4rem 6rem 4rem 6rem;
  padding: 0 6rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  .card-container {
    margin: 2rem 0rem;
    width: 25rem;
    img {
      height: 250px;
    }
    .card-body {
      .card-title {
        color: #db4242;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
      }
      .card-subtitle {
        color: #020202 !important;
        font-size: 14px;
      }
      .card-text {
        color: #02020286;
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 821px) {
  .puja-time-container {
    margin: 4rem;
    padding: 0 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .card-container {
      margin: 2rem;
    }
  }
}
