.info-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .nav-tabs {
    position: static;
  }

  .sigma_img-box {
    display: flex;
    flex-wrap: wrap;
    img {
      width: 270px;
      height: 300px;
    }
  }

  .sigma_img-box img + img {
    margin-top: 40px;
    margin-left: 30px;
  }

  .section-before {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    z-index: -1;
    background-color: #db4242;
  }
  .section-before::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .sigma_shapes.irregular .irregular-2 {
    position: absolute;
    top: -320px;
    left: -430px;
    width: 500px;
    height: 500px;
    transform: rotate(-15deg);
    border-radius: 80px;
  }

  .sigma_shapes.irregular .irregular-3 svg {
    position: absolute;
    bottom: 0px;
    right: -610px;
    width: 920px;
  }

  .sigma_shapes.irregular .irregular-4 svg {
    position: absolute;
    right: -270px;
    top: -40px;
    width: 980px;
    z-index: -1;
  }

  .sigma_shapes.irregular .irregular-5 {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    bottom: -150px;
    left: -150px;
    border: 40px solid #7e4555;
    z-index: -1;
  }

  .sigma_shapes.irregular .irregular-6 svg {
    position: absolute;
    right: 0;
    bottom: -140px;
    width: 1060px;
    z-index: -1;
  }

  .custom-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }
  .custom-img-1 {
    position: absolute;
    bottom: -210px;
    right: 0;
    z-index: -3;
  }

  .spacer {
    height: 140px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
  }

  .sigma_canvas canvas,
  .sigma_canvas {
    width: 100%;
  }
  .sigma_map {
    height: 600px;
    width: 100%;
  }
  .sigma_map .sigma_contact-info {
    left: auto;
    right: 0;
  }
  .sigma_map iframe {
    width: 100%;
    height: 100%;
  }
  .sigma_seperator-vertical {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .sigma_seperator-vertical span {
    width: 1px;
    height: 200px;
    background-color: #efefef;
    display: block;
  }
  .sigma_seperator-vertical span:first-child,
  .sigma_seperator-vertical span:last-child {
    margin: 0 20px;
  }
  .sigma_seperator-vertical span:nth-child(2) {
    height: 400px;
  }

  .stroke-text {
    color: #fff;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    font-weight: 400;
  }

  .stroke-text.stroke-text-dark {
    -webkit-text-stroke-color: #db4242;
  }

  .stroke-text span {
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-width: 0;
  }
  .stroke-text span.custom-primary {
    -webkit-text-fill-color: #7e4555;
    -webkit-text-stroke-width: 0;
  }
  .stroke-text span.text-dark {
    -webkit-text-fill-color: #db4242;
    -webkit-text-stroke-width: 0;
  }

  .sigma_preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #7e4555;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: 0.3s;
  }

  .sigma_preloader.hidden {
    opacity: 0;
    visibility: hidden;
  }

  .sigma_preloader img {
    animation: fadeInOut 3s linear infinite;
  }

  .sigma_preloader ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    -webkit-animation: rot 16s linear infinite;
    animation: rot 16s linear infinite;
  }
  .sigma_preloader li {
    width: 40px;
    height: 40px;
    background: #7e4555;
    border-radius: 4px;
    box-shadow: 0 0 1px #fff, 0 0 5px #7e4555, 0 0 10px #7e4555,
      0 0 15px #7e4555, 0 0 25px #7e4555, 0 0 55px #7e4555;
    -webkit-animation: preloaderScale 0.8s linear alternate infinite;
    animation: preloaderScale 0.8s linear alternate infinite;
  }

  .sigma_preloader li:nth-child(1) {
    z-index: 24;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  .sigma_preloader li:nth-child(2) {
    z-index: 23;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .sigma_preloader li:nth-child(3) {
    z-index: 22;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sigma_preloader li:nth-child(4) {
    z-index: 21;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .sigma_preloader li:nth-child(5) {
    z-index: 20;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .sigma_preloader li:nth-child(6) {
    z-index: 19;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .sigma_preloader li:nth-child(7) {
    z-index: 18;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sigma_preloader li:nth-child(8) {
    z-index: 17;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .sigma_preloader li:nth-child(9) {
    z-index: 16;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .sigma_preloader li:nth-child(10) {
    z-index: 15;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .sigma_preloader li:nth-child(11) {
    z-index: 14;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sigma_preloader li:nth-child(12) {
    z-index: 13;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .sigma_preloader li:nth-child(13) {
    z-index: 12;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .sigma_preloader li:nth-child(14) {
    z-index: 11;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .sigma_preloader li:nth-child(15) {
    z-index: 10;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  .sigma_preloader li:nth-child(16) {
    z-index: 9;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .sigma_preloader li:nth-child(17) {
    z-index: 8;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .sigma_preloader li:nth-child(18) {
    z-index: 7;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .sigma_preloader li:nth-child(19) {
    z-index: 6;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  .sigma_preloader li:nth-child(20) {
    z-index: 5;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }
  .sigma_preloader li:nth-child(21) {
    z-index: 4;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .sigma_preloader li:nth-child(22) {
    z-index: 3;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .sigma_preloader li:nth-child(23) {
    z-index: 2;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  .sigma_preloader li:nth-child(24) {
    z-index: 1;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }
  .sigma_preloader li:nth-child(25) {
    z-index: 0;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }

  .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;

    -webkit-animation: sk-rotate 2s infinite linear;
    animation: sk-rotate 2s infinite linear;
  }

  .dot1,
  .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #7e4555;
    border-radius: 100%;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  a,
  abbr,
  acronym,
  address,
  applet,
  article,
  aside,
  audio,
  b,
  big,
  blockquote,
  body,
  canvas,
  caption,
  center,
  cite,
  code,
  dd,
  del,
  details,
  dfn,
  div,
  dl,
  dt,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  hgroup,
  html,
  i,
  iframe,
  img,
  ins,
  kbd,
  label,
  legend,
  li,
  mark,
  menu,
  nav,
  object,
  ol,
  output,
  p,
  pre,
  q,
  ruby,
  s,
  samp,
  section,
  small,
  span,
  strike,
  strong,
  sub,
  summary,
  sup,
  table,
  tbody,
  td,
  tfoot,
  th,
  thead,
  time,
  tr,
  tt,
  u,
  ul,
  var,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
  }

  .opacity-0 {
    opacity: 0;
  }

  .opacity-25 {
    opacity: 0.25;
  }

  .opacity-50 {
    opacity: 0.5;
  }

  .opacity-75 {
    opacity: 0.75;
  }

  .opacity-100 {
    opacity: 1;
  }

  .pattern-building,
  .pattern-squares,
  .pattern-map,
  .primary-overlay,
  .light-overlay,
  .secondary-overlay,
  .dark-overlay {
    position: relative;
    z-index: 1;
  }

  .dark-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .light-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: -1;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .primary-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(18 29 49 / 73%);
    z-index: -1;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .secondary-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(2 33 71 / 62%);
    z-index: -1;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  .dark-overlay.primary-overlay::before {
    background-color: rgba(72, 172, 110, 0.8);
  }

  .dark-overlay.dark-overlay-2::before {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .dark-overlay.dark-overlay-3::before {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .sigma_sticky-section {
    position: sticky;
    top: 30px;
  }

  html {
    overflow-x: hidden;
  }

  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.9;
    font-weight: 400;
    color: #767e88;
    overflow-x: hidden;
  }

  hr {
    margin: 30px 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .imgs-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .imgs-wrapper img {
    position: absolute;
    max-width: 300px;
  }

  .imgs-wrapper img:nth-child(1) {
    top: 150px;
    max-width: 400px;
    left: -200px;
  }

  .imgs-wrapper img:nth-child(2) {
    right: -100px;
    bottom: 0;
  }

  .sigma_single-img-wrapper {
    position: relative;
  }

  .sigma_single-img-wrapper img {
    border-radius: 8px;
  }

  .signature {
    width: 220px;
    margin-bottom: 20px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .close-btn {
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    outline: none;
  }

  .close-btn:focus {
    outline: none;
  }

  .close-btn span {
    position: absolute;
    width: 2px;
    height: 15px;
    display: block;
    background-color: #fff;
    opacity: 0.6;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .close-btn.close-dark span {
    background-color: #db4242;
  }

  .close-btn.close-danger span {
    background-color: red;
  }

  .close-btn span:nth-child(2) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .close-btn:hover {
    transform: rotate(45deg);
  }

  .close-btn.dark span {
    background-color: #db4242;
  }

  .icon-2x {
    font-size: 2rem;
  }

  .icon-3x {
    font-size: 3rem;
  }

  .icon-4x {
    font-size: 4rem;
  }

  .border-0 {
    border: 0;
  }

  .bg-cover {
    background-size: cover;
  }

  .bg-lg {
    background-size: 600px;
  }

  .bg-parallax {
    background-attachment: fixed;
  }

  .bg-contain {
    background-size: contain;
  }

  .bg-norepeat {
    background-repeat: no-repeat;
  }

  .bg-center {
    background-position: center;
  }

  .bg-left {
    background-position: left;
  }

  .bg-right {
    background-position: right;
  }

  .bg-top {
    background-position: top;
  }

  .bg-bottom {
    background-position: bottom;
  }
  .bg-bottom-left {
    background-position: bottom left;
  }
  .bg-bottom-right {
    background-position: bottom right;
  }

  .container-fluid {
    padding-left: 150px;
    padding-right: 150px;
  }

  .sigma_menu-vertical {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .sigma_menu-vertical li,
  .sigma_menu-vertical li + li {
    margin: 0;
  }
  .sigma_menu-vertical li a {
    padding: 40px 20px;
    display: block;
    color: #777777;
    text-transform: uppercase;
  }
  .sigma_menu-vertical li a:hover {
    color: #fff;
  }

  /*======================
  1.1. Typography
  ========================*/

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Poppins", sans-serif;
    margin: 0 0 20px;
    line-height: 1.2;
    color: #db4242;
    font-weight: 700;
  }

  h1 {
    font-size: 52px;
  }

  h2 {
    font-size: 44px;
  }

  h3 {
    font-size: 36px;
    line-height: 1.2;
  }

  h4 {
    font-size: 28px;
    line-height: 1.4;
  }

  h5 {
    font-size: 24px;
    line-height: 1.3;
  }

  h6 {
    font-size: 18px;
    line-height: 1.7;
  }

  p {
    font-size: 14px;
    line-height: 1.9;
    margin-bottom: 15px;
    color: #777;
  }

  a {
    color: #7e4555;
    text-decoration: none;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: #db4242;
  }

  h1 a:hover,
  h2 a:hover,
  h3 a:hover,
  h4 a:hover,
  h5 a:hover,
  h6 a:hover,
  h1 a:focus,
  h2 a:focus,
  h3 a:focus,
  h4 a:focus,
  h5 a:focus,
  h6 a:focus {
    color: #ec6a47;
  }

  a:hover,
  a:focus {
    color: #ec6a47;
    text-decoration: none;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }

  b,
  strong {
    font-weight: 700;
    color: #db4242;
  }

  b span,
  strong span {
    color: #777;
  }

  label {
    margin-bottom: 10px;
    color: #db4242;
    font-weight: 600;
  }

  label.error {
    color: #ff0000;
    display: block;
    font-size: 13px;
    font-weight: normal;
    text-align: left;
  }

  ol,
  ul {
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 20px;
  }

  ul li,
  ol li {
    margin: 10px 0 0;
    position: relative;
  }
  .sigma_list {
    margin: 40px 0;
  }
  .sigma_list.list-2 {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  .sigma_list li {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    color: #db4242;
    font-weight: 600;
  }

  .sigma_list li + li {
    margin-top: 20px;
  }

  .sigma_list li::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f00c";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-rendering: auto;
    line-height: 1;
    font-size: 20px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-right: 10px;
    color: #7e4555;
    background-color: #e6e8e7;
  }

  .sigma_list.sigma_list-2 li {
    font-weight: 400;
    font-size: 14px;
  }

  .sigma_list.sigma_list-2 li::before {
    width: auto;
    height: auto;
    background-color: transparent;
  }

  .small,
  small {
    line-height: 1.7;
  }

  .blockquote,
  blockquote {
    position: relative;
    margin: 20px 0;
    font-size: 14px;
    color: #db4242;
    padding: 10px 30px;
    background-position: center;
    background-size: cover;
    background-color: #f7f7f7;
    border-left: 2px solid #7e4555;
  }

  p.blockquote:before,
  p.blockquote:after {
    content: "";
    position: absolute;
    left: -9px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 30px;
    background-color: #7e4555;
  }

  p.blockquote:after {
    left: 6px;
  }

  p.blockquote.light-border:before,
  p.blockquote.light-border:after {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .blockquote.light {
    background-color: transparent;
    color: #fff;
  }
  .blockquote.bg-transparent {
    color: #020202;
  }
  .blockquote.light-border,
  blockquote.light-border {
    border-left-color: rgba(255, 255, 255, 0.4);
  }

  blockquote {
    border-left: 0;
    text-align: center;
    padding: 50px;
    border-radius: 10px;
  }

  .entry-content blockquote p,
  blockquote p {
    position: relative;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 700;
    color: #db4242;
  }

  blockquote cite {
    position: relative;
    font-weight: 600;
    text-transform: uppercase;
    color: #7e4555;
    display: block;
    margin: 0 0 10px;
  }

  blockquote::before {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 240px;
    content: "\f10e";
    color: #fff;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }

  .entry-content p,
  .entry-content img {
    margin-bottom: 20px;
  }

  .entry-content .sigma_post-share {
    margin-bottom: 20px;
  }

  .entry-content p a {
    font-weight: 600;
    text-decoration: underline;
  }

  .fs-10 {
    font-size: 10px;
  }

  .fs-12 {
    font-size: 12px;
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-15 {
    font-size: 14px;
  }

  .fs-16 {
    font-size: 16px;
  }

  .fs-18 {
    font-size: 18px;
  }

  .fw-400 {
    font-weight: 400 !important;
  }

  .fw-500 {
    font-weight: 500;
  }

  .fw-600 {
    font-weight: 600;
  }

  .fw-700 {
    font-weight: 700;
  }

  .border-25 {
    border-radius: 25px;
  }

  .sigma_image-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .transform-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
  }

  /*======================
  1.2 Section Spacings
  ========================*/
  .section {
    position: relative;
    padding: 110px 0;
    &:first-child {
      padding-top: 50px;
      padding-bottom: 0;
    }
    &:not(:first-child) {
      padding-top: 0;
    }
  }
  .section .container {
    position: relative;
  }

  .section.section-padding {
    padding: 110px 0 80px;
  }
  .section.section-lg {
    padding: 110px 0 200px;
  }
  .section.section-md {
    padding: 110px 0 160px;
  }

  .sigma_section-fw {
    padding: 0 80px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }

  .me-lg-30 {
    margin-left: 30px;
  }

  .section.section-padding.category_section {
    margin-top: -50px;
    padding-top: 0;
  }
  .section-title .badge {
    margin-bottom: 10px;
  }

  .section-title .subtitle {
    position: relative;
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: #7e4555;
    font-weight: 700;
    img {
      width: 3rem;
    }
  }
  .section-title.text-start .subtitle {
    justify-content: flex-start;
  }

  .section-title.text-md-right .subtitle,
  .section-title.text-end .subtitle {
    justify-content: flex-end;
  }

  .section-title.text-md-right,
  .section-title.text-end {
    margin: 0 0 50px auto;
  }

  .section-title.text-md-right .subtitle::after,
  .section-title.text-end .subtitle::after,
  .section-title.text-start .subtitle::after {
    display: none;
  }

  //   .section-title .subtitle::after,
  //   .section-title .subtitle::before {
  //     content: "\f679";
  //     font-family: "Font Awesome 5 Pro";
  //     -moz-osx-font-smoothing: grayscale;
  //     -webkit-font-smoothing: antialiased;
  //     display: inline-block;
  //     font-style: normal;
  //     font-variant: normal;
  //     text-rendering: auto;
  //     line-height: 1;
  //     font-size: 18px;
  //     margin-right: 15px;
  //   }

  .section-title .subtitle::after {
    margin-right: 0;
    margin-left: 15px;
  }

  .section-title p {
    font-size: 16px;
  }

  .section-title.section-title-2 {
    position: relative;
  }
  .section-title.section-title-2 .subtitle {
    color: #7e4555;
    background-color: #e6e8e7;
    display: inline-block;
    padding: 5px 20px;
    font-size: 13px;
  }
  .section-title.section-title-2 .subtitle.light {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .section-title.section-title-2 .subtitle::before,
  .section-title.section-title-2 .subtitle::after {
    display: none;
  }
  .small-text {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 16px;
  }

  .section-title .title {
    position: relative;
    margin: 0;
    font-size: 25px;
    line-height: 1.2;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .section-title {
    position: relative;
    margin-bottom: 50px;
    max-width: 500px;
  }
  .section-title.text-center {
    margin: 0 auto 50px;
  }

  .section-title.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .section-title.flex-title .nav {
    margin-left: auto;
  }

  .section-title.flex-title .nav-item {
    margin: 0;
  }

  .section-title.flex-title .nav-item + .nav-item {
    margin-left: 10px;
  }

  .section-title.flex-title .nav-item .nav-link {
    margin: 0;
  }

  .section-button {
    margin-top: 30px;
  }

  .ov-hidden {
    overflow: hidden;
  }

  /*==================
  1.3. Forms
  ====================*/
  textarea {
    resize: none;
  }

  textarea.form-control {
    height: auto;
  }

  .form-control::-webkit-input-placeholder {
    color: #a5a5a5;
  }

  .form-control::-moz-placeholder {
    color: #a5a5a5;
  }

  .form-control:-ms-input-placeholder {
    color: #a5a5a5;
  }

  .form-control::-ms-input-placeholder {
    color: #a5a5a5;
  }

  .form-control::placeholder {
    color: #a5a5a5;
  }

  .form-control {
    height: 60px;
    padding: 8px 15px;
    border-radius: 10px;
    border: 2px solid #efefef;
    width: 100%;
    color: #6e6e6e;
    background-color: #fff;
    letter-spacing: 0.1px;
    font-size: 14px;
    appearance: auto;
    -webkit-appearance: auto;
  }

  .form-control.transparent {
    background-color: rgba(255, 255, 255, 0.1);
    border: 0;
  }
  .form-control.transparent:focus {
    background-color: rgba(255, 255, 255, 0.2);
    border: 0;
  }

  .form-control.dark {
    background-color: #f7f7f7;
    border: 0;
  }
  .form-control.dark:focus {
    background-color: #eaeaea;
    border: 0;
  }

  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #efefef;
  }

  .form-group {
    position: relative;
    margin-bottom: 20px;
  }
  .form-group i {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #e8e8e8;
  }

  .form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }

  .input-with-icon {
    position: relative;
  }

  .input-with-icon input,
  .input-with-icon select {
    padding-left: 40px;
  }

  .input-with-icon i {
    position: absolute;
    top: 50%;
    left: 15px;
    font-size: 18px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #a5a5a5;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .input-with-icon input:focus + i,
  .input-with-icon select:focus + i {
    color: #454545;
  }

  /*Checkboxes and radios*/
  input[type="checkbox"],
  input[type="radio"] {
    opacity: 0;
    position: absolute;
  }

  input[type="checkbox"] + label,
  input[type="radio"] + label {
    display: block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    font-weight: 400;
  }

  input[type="checkbox"] + label:before,
  input[type="radio"] + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: 1px solid #efefef;
    border-radius: 3px;
    display: inline-block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 16px;
    outline: 0;
    -webkit-transition: background-color 0.2s ease-out;
    -o-transition: background-color 0.2s ease-out;
    transition: background-color 0.2s ease-out;
    width: 16px;
  }

  input[type="radio"] + label:before {
    border-radius: 50%;
  }

  input[type="checkbox"] + label:after,
  input[type="radio"] + label:after {
    position: absolute;
    top: 50%;
    left: 3px;
    width: 10px;
    height: 5px;
    content: " ";
    transform: translateY(-50%) rotate(-45deg);
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    opacity: 0;
    transition: 0.3s;
  }

  input[type="radio"] + label:after {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 0;
    background-color: #7e4555;
  }

  input[type="checkbox"]:checked + label:before,
  input[type="checkbox"]:checked + label.switch-toggle:before {
    background-color: #7e4555;
    border-color: #7e4555;
  }

  input[type="checkbox"]:checked + label:after,
  input[type="radio"]:checked + label:after {
    opacity: 1;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::before,
  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background-color: #7e4555;
  }

  .custom-control label {
    font-weight: 400;
  }
  .custom-form {
    padding: 40px 40px 10px;
    background-color: #db4242;
  }
  .custom-form .form-control {
    background-color: transparent;
    border: 2px solid #383838;
    color: #fff;
  }
  .custom-form .form-group i {
    color: #fff;
    height: 100%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    border-left: 2px solid #383838;
  }

  /*==================
  1.4. Buttons
  ====================*/
  .btn-link {
    position: relative;
    color: #db4242;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
  }
  .btn-link.h1 {
    font-size: 2.5rem;
    max-width: 230px;
    display: block;
  }

  .btn-link:hover {
    color: #db4242;
    text-decoration: none;
  }
  .btn-link i {
    transition: 0.3s;
    margin-left: 5px;
  }
  .btn-link:hover i {
    transform: translateX(5px);
  }

  .input-group-text {
    color: #fff;
    padding: 5px 20px;
    font-size: 22px;
    background-color: #7e4555;
    border: 1px solid #7e4555;
  }

  .sigma_btn-custom {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    background-color: #7e4555;
    border: 0;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 15px 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border-radius: 30px;
    font-weight: 600;
    overflow: hidden;
    z-index: 1;
  }

  .sigma_btn-custom::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    background-color: #d43308;
    transform: scaleY(0);
    transform-origin: 50%;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  .sigma_btn-custom:focus::before,
  .sigma_btn-custom:hover::before {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }

  .sigma_btn-custom:hover,
  .sigma_btn-custom:focus {
    color: #fff;
    outline: none;
  }
  .sigma_btn-custom.primary {
    background-color: #7e4555;
  }

  .sigma_btn-custom.transparent {
    background-color: transparent;
    border: 1px solid transparent;
    color: #db4242;
  }
  .sigma_btn-custom.transparent::before {
    background-color: #ec6a47;
  }
  .sigma_btn-custom.transparent:hover,
  .sigma_btn-custom.transparent:focus {
    color: #fff;
    border: 1px solid #efefef;
  }

  .sigma_btn-custom.primary::before {
    background-color: #ec6a47;
  }

  .sigma_btn-custom.dark {
    background-color: #db4242;
    color: #fff;
    box-shadow: none;
  }
  .sigma_btn-custom.dark::before {
    background-color: #65303f;
  }
  .sigma_btn-custom.light:hover,
  .sigma_btn-custom.light:focus,
  .sigma_btn-custom.dark:hover,
  .sigma_btn-custom.dark:focus {
    color: #fff;
  }
  .sigma_btn-custom.light {
    background-color: transparent;
    color: #db4242;
    box-shadow: none;
    border: 2px solid #efefef;
  }

  .sigma_btn-custom.light::before {
    background-color: #282828;
  }
  .sigma_btn-custom.white {
    background-color: #fff;
    color: #db4242;
    box-shadow: none;
  }
  .sigma_btn-custom.white::before {
    background-color: #efefef;
  }

  .sigma_btn-custom.secondary {
    background-color: #db4242;
    color: #fff;
  }

  .sigma_btn-custom.secondary::before {
    background-color: #65303f;
  }

  .sigma_btn-custom.btn-sm {
    padding: 12px 26px;
    font-size: 14px;
  }
  .sigma_btn-custom.btn-pill {
    border-radius: 55px;
  }

  .sigma_btn-custom i {
    margin-left: 10px;
  }

  .btn-group .sigma_btn-custom + .sigma_btn-custom {
    padding: 12px 14px;
  }

  .btn-group .sigma_btn-custom.btn-sm + .sigma_btn-custom.btn-sm {
    padding: 12px 16px;
  }

  .input-group-prepend,
  .input-group-append {
    margin: 0;
    display: flex;
  }

  .input-group-prepend .sigma_btn-custom,
  .input-group-prepend .sigma_btn-custom::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .input-group-append .sigma_btn-custom,
  .input-group-append .sigma_btn-custom::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .btn-group
    .sigma_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after {
    animation-name: BounceUpDown;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
  }

  .btn-group.dropup
    .sigma_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after {
    animation-name: BounceDownUp;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
  }

  .sigma_round-button {
    position: relative;
    display: inline-block;
  }

  .sigma_round-button > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 40px;
    line-height: 1;
    color: #db4242;
  }
  .sigma_round-button > span span {
    font-size: 20px;
  }
  .sigma_round-button:hover {
    color: #777;
  }
  .sigma_round-button svg {
    width: 160px;
    height: 160px;
    overflow: visible;
  }
  .sigma_round-button i {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .sigma_round-button .sigma_round-button-stroke {
    fill: none;
    stroke: #efefef;
    stroke-width: 10;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    position: relative;
    z-index: -1;
    color: #efefef;
  }
  .sigma_round-button .sigma_round-button-circle {
    fill: none;
    stroke: #7e4555;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    stroke-dashoffset: 700;
    stroke-dasharray: 700;
    stroke-width: 20px;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-linecap: butt;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 2s;
    color: #7e4555;
    stroke-linecap: round;
  }

  .sigma_round-button .sigma_round-button-circle.secondary {
    color: #db4242;
    stroke: #db4242;
  }

  .sigma_round-button.sm svg {
    width: 100px;
    height: 100px;
  }
  .sigma_round-button.sm > span {
    font-size: 25px;
  }
  .sigma_round-button.sm > span span {
    font-size: 14px;
  }

  /*==================
  1.5. Colors & Badges
  ====================*/
  .custom-primary {
    color: #7e4555 !important;
  }
  .custom-secondary {
    color: #db4242;
  }

  .twitter {
    background-color: rgb(44, 170, 225) !important;
    color: #fff;
  }

  .twitter:hover {
    background-color: #2caae1;
    color: #fff;
  }

  .facebook {
    background-color: rgb(59, 87, 157) !important;
    color: #fff;
  }

  .facebook:hover {
    background-color: #3b579d;
    color: #fff;
  }

  .google {
    background-color: rgb(220, 74, 56) !important;
    color: #fff;
  }

  .google:hover {
    background-color: #dc4a38;
    color: #fff;
  }

  .linkedin {
    background-color: rgb(1, 119, 181) !important;
    color: #fff;
  }

  .linkedin:hover {
    background-color: #0177b5;
    color: #fff;
  }

  .pinterest {
    background-color: rgb(204, 33, 39) !important;
    color: #fff;
  }

  .pinterest:hover {
    background-color: #cc2127;
    color: #fff;
  }

  .youtube {
    background-color: rgb(229, 45, 39);
    color: #fff;
  }

  .youtube:hover {
    background-color: #e52d27;
    color: #fff;
  }

  .github {
    background-color: rgb(51, 51, 51) !important;
    color: #fff;
  }

  .github:hover {
    background-color: #333333 !important;
    color: #fff;
  }

  .behance {
    background-color: rgb(23, 105, 255) !important;
    color: #fff;
  }

  .behance:hover {
    background-color: #1769ff;
    color: #fff;
  }

  .dribbble {
    background-color: rgb(234, 76, 137) !important;
    color: #fff;
  }

  .dribbble:hover {
    background-color: #ea4c89;
    color: #fff;
  }

  .reddit {
    background-color: rgb(255, 69, 0) !important;
    color: #fff;
  }

  .reddit:hover {
    background-color: #ff4500;
    color: #fff;
  }

  .light-bg {
    background-color: #f7f7f7;
  }
  .dark-bg {
    background-color: #db4242;
  }
  .dark-bg-2 {
    background-color: #282828;
  }
  .border-bottom-dark {
    border-bottom: 1px solid #2d2d2d;
  }

  .primary-bg {
    background-color: #7e4555;
  }
  .primary-dark-bg {
    background-color: #ec6a47;
  }

  .primary-light-bg {
    background-color: #f0fff0;
  }

  .secondary-bg {
    background-color: #db4242;
  }

  .tirtery-bg {
    background: linear-gradient(184deg, rgb(126 69 85) 0%, rgb(219 66 66) 100%);
  }

  .sigma_img-custom {
    position: relative;
    z-index: 1;
  }
  .sigma_img-custom img {
    width: 100%;
  }

  .badge {
    background-color: #f0fff0;
    padding: 6px 11px;
    font-size: 18px;
    border-radius: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.2;
  }
  .badge.badge-primary {
    color: #7e4555;
    background-color: #f0fff0;
  }
  .badge.badge-secondary {
    color: #db4242;
    background-color: #db4242;
  }

  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
  }

  /*======================
  1.8 Tabs
  ========================*/
  .tab-content {
    margin-top: 20px;
  }

  .nav-item {
    margin: 0;
  }
  .nav-tabs {
    border-bottom: 0;
  }
  .nav-tabs .nav-link {
    padding: 10px 20px;
    font-weight: 600;
    color: #db4242;
    background-color: #f7f7f7;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    color: #7e4555;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link {
    border: 0;
    border-radius: 0;
  }
  .nav-tabs .nav-item + .nav-item {
    margin-left: 5px;
  }
  .nav-tabs .nav-link i {
    margin-right: 5px;
    color: #7e4555;
  }
  .sigma_product-additional-info .nav-link.active,
  .nav-link.active {
    border-bottom: 2px solid #7e4555;
    font-weight: 600;
    color: #7e4555;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-bottom: 2px solid #7e4555;
    font-weight: 600;
  }

  /*======================
  1.9. Social media links
  ========================*/
  .sigma_sm {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .sigma_sm li {
    margin: 0;
  }

  .sigma_sm li a {
    display: inline-block;
  }

  .sigma_sm li + li {
    margin-left: 10px;
  }

  .sigma_sm.square li a {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }

  .sigma_sm.square.light li a {
    background-color: #f7f7f7;
    color: #db4242;
  }

  .sigma_sm.square.light li a:hover,
  .sigma_sm.square li a:hover {
    background-color: #7e4555;
    color: #fff;
  }

  /*======================
  2. Header
  ========================*/

  .sigma_header.header-fw .sigma_header-top > .container,
  .sigma_header.header-fw .sigma_header-middle > .container,
  .sigma_header.header-fw .sigma_header-bottom > .container {
    width: 100%;
    max-width: 100%;
    padding-left: 4vw;
    padding-right: 4vw;
  }

  @keyframes fadeHeaderInDown {
    0% {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .sigma_header.sticky.header-1 .sigma_header-middle {
    position: fixed;
    top: -100%;
    width: 100%;
    z-index: 990;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  .sigma_header.header-1.sticky .sigma_header-middle {
    top: 0;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
    animation-name: fadeHeaderInDown;
  }
  .sigma_header.header-1.sticky:not(.header-light) .sigma_header-middle {
    background-color: #fff;
  }

  .sigma_header.header-1.sticky
    .sigma_header-middle
    .navbar
    > .navbar-nav
    > .menu-item
    > a:hover {
    color: #7e4555;
  }

  .header-absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .header-absolute .sigma_header-middle,
  .header-absolute .sigma_header-bottom,
  .header-absolute .sigma_header-top {
    background-color: transparent;
  }
  .header-absolute.sigma_header {
    padding: 10px 0;
  }

  .sigma_header.header-1.sticky .sigma_header-middle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 190;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
    background-color: #fff;
  }
  .sigma_header.header-1.sticky .aside-toggler span {
    background-color: #fff;
  }
  .sigma_header.header-1.sticky .aside-toggler:hover span {
    background-color: #7e4555;
  }
  .sigma_header.header-1.sticky .sigma_header-controls-inner li a i,
  .sigma_header.header-1.sticky.header-light
    .sigma_header-controls-inner
    li
    a
    i {
    color: #fff;
  }
  .sigma_header.header-1.sticky .aside-toggler.desktop-toggler span,
  .sigma_header.header-1.sticky.header-light
    .aside-toggler.desktop-toggler
    span {
    background-color: #fff;
  }

  .sigma_header-top {
    background-color: #db4242;
    border-bottom: 1px solid #db4242;
    padding: 0;
  }

  .sigma_header-top .sigma_sm li + li {
    margin-left: 20px;
  }

  .header-absolute .sigma_header-top {
    border-bottom: 1px solid #efefef;
  }

  .sigma_header-top-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sigma_header-top-links {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .sigma_header-top-links li {
    margin: 0;
  }
  .sigma_header-top-links li a i {
    margin-right: 10px;
  }
  .sigma_header-top-links > li + li > a {
    padding-left: 15px;
    margin-left: 15px;
    border-left: 1px solid #db4242;
  }

  .sigma_header-top .live {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
  }

  .sigma_header-top .live i {
    font-size: 10px;
    color: #7e4555;
    transition: 0.3s;
    margin-right: 4px;
    animation: fadeInOut 2s linear infinite;
  }
  .header-absolute .sigma_header-top-links > li + li > a {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }

  .sigma_header-top-sm li a,
  .sigma_header-top-links a {
    color: #fff;
  }

  .sigma_header-top-sm li a:hover,
  .sigma_header-top-links a:hover {
    color: #7e4555;
  }

  .sigma_header-top-links a {
    font-weight: 400;
    font-size: 13px;
    padding: 15px 0;
    line-height: normal;
    display: flex;
    align-items: center;
  }

  .sigma_header-top-links .sigma_account a {
    padding: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 10px 0;
  }

  .sigma_header-top-cta a {
    height: 100%;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sigma_header-top-user {
    margin-left: 30px;
    max-width: 40px;
  }

  .sigma_header-middle {
    background-color: #fff;
  }

  .sigma_header-middle .navbar {
    padding: 0;
  }

  .navbar-brand {
    padding: 10px 0;
    width: 190px;
  }

  /* Logo */
  .header-2 .sigma_logo-wrapper {
    position: relative;
    background-color: #fff;
    height: 113px;
    padding: 0 30px;
    margin-bottom: -30px;
    z-index: 3;
    display: flex;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
    align-items: center;
  }
  .header-2 .sigma_logo-wrapper .navbar-brand {
    margin-right: 0;
  }

  /* Controls */
  .sigma_header-controls {
    display: flex;
    align-items: center;
  }

  .sigma_header-controls-inner {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .sigma_header-controls-inner li {
    margin: 0;
    position: relative;
  }

  .sigma_header-controls-inner li + li {
    margin-left: 15px;
  }

  .sigma_header-controls-inner li a {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
  }

  .sigma_header-controls-inner li a i {
    font-size: 21px;
    color: #db4242;
  }

  .sigma_header-controls-inner li a:hover {
    background-color: #f7f7f7;
  }

  .sigma_header-controls-inner li:last-child a {
    margin-right: 0;
  }

  /* cart and cart dropdown */

  .sigma_header-controls.style-2
    .sigma_header-controls-inner
    li.sigma_header-cart
    > a,
  .sigma_header-controls.style-2
    .sigma_header-controls-inner
    li.sigma_header-wishlist
    > a {
    width: auto;
    height: auto;
    font-weight: 600;
    border-radius: 50%;
    border: 0;
    padding: 23px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-3
    .sigma_header-controls.style-2
    .sigma_header-controls-inner
    li.sigma_header-cart
    > a,
  .header-3
    .sigma_header-controls.style-2
    .sigma_header-controls-inner
    li.sigma_header-wishlist
    > a {
    padding: 20px 6px;
  }

  .sigma_header-controls.style-2
    .sigma_header-controls-inner
    li.sigma_header-cart
    > a:hover,
  .sigma_header-controls.style-2
    .sigma_header-controls-inner
    li.sigma_header-wishlist
    > a:hover {
    background-color: transparent;
  }

  .sigma_header-controls.style-2 .sigma_header-controls-inner li > a:hover i,
  .sigma_header-controls.style-2 .sigma_header-controls-inner li > a:hover i {
    color: #7e4555;
  }

  .sigma_header-controls.style-2
    .sigma_header-controls-inner
    li.sigma_header-cart
    .number {
    position: absolute;
    right: -7px;
    top: 15px;
    width: 20px;
    height: 20px;
    background-color: #7e4555;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    line-height: 0;
  }

  .header-3
    .sigma_header-controls.style-2
    .sigma_header-controls-inner
    li.sigma_header-cart
    .number {
    top: 5px;
  }

  .sigma_header-controls.style-2 .sigma_header-controls-inner li a i,
  .sigma_header-controls.style-2 .sigma_header-controls-inner li a i {
    font-size: 20px;
    color: #777;
    line-height: 1;
  }

  .sigma_header-controls-inner li.sigma_header-cart .sigma_header-cart-content {
    margin-left: 15px;
  }

  .sigma_header-controls-inner
    li.sigma_header-cart
    .sigma_header-cart-content
    span {
    display: block;
    font-size: 13px;
    font-weight: 600;
    color: #db4242;
  }

  .sigma_header-controls-inner
    li.sigma_header-cart
    .sigma_header-cart-content
    span
    + span {
    color: #7e4555;
  }

  .sigma_header .sigma_header-controls .cart-dropdown {
    display: block;
    left: auto;
    right: 0;
  }
  .sigma_header .sigma_header-controls .cart-dropdown li + li {
    margin-left: 0;
  }

  .sigma_header .sigma_header-controls.style-2 .cart-dropdown li a {
    border-bottom: 1px solid #efefef;
    transition: 0.3s;
  }

  .sigma_header .sigma_header-controls .cart-dropdown li h6 {
    margin-bottom: 5px;
  }
  .sigma_header .sigma_header-controls .cart-dropdown li a:hover h6 {
    color: #7e4555;
  }

  /* Controls Style 2 */
  .sigma_header-controls.style-2 .aside-toggler.desktop-toggler,
  .sigma_header-controls.style-2 .sigma_header-controls-inner li a {
    width: auto;
    height: auto;
    border-radius: 0;
    border: 0;
  }
  .sigma_header-controls.style-2 .aside-toggler span {
    margin-left: auto;
  }

  .sigma_header-controls.style-2 .sigma_header-controls-inner li a:hover {
    background-color: transparent;
  }

  .sigma_header-inner ul.navbar-nav > li > a {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Header Bottom */
  .sigma_header-bottom {
    background-color: #db4242;
  }

  .sigma_header-bottom-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sigma_header-bottom-inner .navbar-nav,
  .sigma_header-middle .navbar-nav {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .sigma_header-bottom-inner .navbar-nav .menu-item,
  .sigma_header-middle .navbar-nav .menu-item {
    margin: 0;
  }

  .sigma_header-bottom-inner > .navbar-nav > .menu-item > a,
  .sigma_header-middle .navbar > .navbar-nav > .menu-item > a {
    position: relative;
    padding: 20px;
    color: #fff;
    display: block;
    font-size: 15px;
    font-weight: 600;
  }

  .sigma_header-middle .navbar > .navbar-nav > .menu-item > a {
    color: #000000;
    padding: 30px 15px;
    text-transform: uppercase;
    font-size: 13px;
  }
  .sigma_header-middle .navbar > .navbar-nav > .menu-item > a::before {
    content: "";
    width: 100%;
    height: 0;
    transition: 0.3s;
    background-color: #fff;
  }
  .sigma_header-middle .navbar > .navbar-nav > .menu-item > a:hover::before {
    height: 100%;
  }

  .sigma_header-bottom-inner > .navbar-nav > .menu-item > a:hover,
  .sigma_header-middle .navbar > .navbar-nav > .menu-item > a:hover {
    color: #7e4555;
  }

  .sigma_header-bottom-inner > .navbar-nav > .menu-item:first-child > a,
  .sigma_header-middle .navbar > .navbar-nav > .menu-item:first-child > a {
    padding-left: 0;
  }

  .sigma_header-bottom-inner .navbar-nav .menu-item-has-children,
  .sigma_header-middle .navbar-nav .menu-item-has-children {
    position: relative;
  }

  .sigma_header .sub-menu,
  .sigma_flex-menu .sub-menu,
  .sigma_header-controls .cart-dropdown {
    position: absolute;
    top: 105%;
    left: 0;
    border-radius: 0;
    width: 100%;
    background-color: #fff;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    min-width: 230px;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
  }

  .sigma_flex-menu > ul {
    display: flex;
    margin: 0;
    align-items: center;
  }

  .sigma_flex-menu ul li {
    margin: 0;
  }

  .sigma_flex-menu > ul > li > a {
    padding: 0 10px 20px 10px;
    display: block;
    font-weight: 600;
    color: #db4242;
  }

  .sigma_flex-menu > ul > li > a:hover {
    color: #7e4555;
  }

  .sigma_flex-menu > ul > li:first-child > a {
    padding-left: 0;
  }

  .sigma_header .sub-menu.sub-menu-left,
  .sigma_flex-menu .sub-menu.sub-menu-left {
    left: auto;
    right: 0;
  }

  .sigma_header-middle .navbar-nav .menu-item-has-children:hover > .sub-menu,
  .sigma_header-bottom-inner
    .navbar-nav
    .menu-item-has-children:hover
    > .sub-menu,
  .sigma_search-adv-cats:hover .sub-menu,
  .sigma_header-top-links .menu-item-has-children:hover .sub-menu,
  .sigma_flex-menu .menu-item-has-children:hover .sub-menu,
  .sigma_header-controls > ul li:hover .cart-dropdown {
    top: 100%;
    opacity: 1;
    visibility: visible;
  }

  .sigma_header .sub-menu a,
  .sigma_flex-menu .sub-menu a,
  .sigma_header-controls .cart-dropdown a,
  .sigma_header-controls .cart-dropdown p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    color: #777;
    font-weight: 400;
  }

  .sigma_header .sub-menu a:hover,
  .sigma_flex-menu .sub-menu a:hover {
    color: #7e4555;
    transform: translateX(5px);
  }

  .sigma_header-middle .navbar-nav .menu-item-has-children .sub-menu .sub-menu,
  .sigma_header-bottom-inner
    .navbar-nav
    .menu-item-has-children
    .sub-menu
    .sub-menu,
  .sigma_search-adv-cats .sub-menu .sub-menu,
  .sigma_header-top-links .menu-item-has-children .sub-menu .sub-menu,
  .sigma_flex-menu .menu-item-has-children .sub-menu .sub-menu {
    top: 0;
    left: 100%;
  }

  .sigma_header .sub-menu .menu-item-has-children > a > span,
  .sigma_flex-menu .sub-menu .menu-item-has-children > a > span {
    transform: rotate(-90deg);
  }

  .sigma_search-adv form {
    display: flex;
  }

  .sigma_search-adv-cats {
    position: relative;
    background-color: #f7f7f7;
    border-radius: 25px 0 0 25px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .sigma_search-adv-cats label {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    color: #777;
    font-weight: 400;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    margin: 0;
    transition: 0.3s;
  }

  .sigma_header .sub-menu label:hover {
    background-color: #f7f7f7;
  }

  .sigma_search-adv-cats label input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .sigma_search-adv-cats label input + i {
    color: #30ca71;
    transition: 0.3s;
    visibility: hidden;
    margin-left: auto;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    font-size: 12px;
  }

  .sigma_search-adv-cats label input:checked + i {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  .sigma_search-adv-cats > span {
    color: #db4242;
    font-size: 13px;
    font-weight: 600;
  }

  .sidebar-widget .sigma_search-adv-input {
    width: 100%;
  }

  .sidebar-widget .custom-control-label {
    font-weight: 400;
  }

  .sigma_search-adv-input {
    position: relative;
  }
  .sigma_search-adv-input .form-control {
    background-color: #a3596e;
    border: 0;
    color: #fff;
    box-shadow: none;
  }
  .sidebar-widget .sigma_search-adv-input .form-control {
    background-color: #f7f7f7;
    color: #6e6e6e;
  }
  .sidebar-widget .sigma_search-adv-input .form-control::placeholder {
    color: #a5a5a5;
  }
  .sigma_search-adv-input .form-control::placeholder {
    color: #fff;
  }
  .sigma_search-adv-input button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 0;
    background-color: #5c3943;
    color: #fff;
    transition: 0.3s;
  }

  .sigma_search-adv-input button:hover {
    color: #fff;
  }

  .sigma_search-adv-cats .sub-menu li,
  .sigma_search-adv-cats .sub-menu {
    margin: 0;
  }

  .sigma_search-form-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  .sigma_search-form-wrapper.open {
    opacity: 1;
    visibility: visible;
  }
  .sigma_search-form-wrapper .close-btn {
    position: absolute;
    top: 60px;
    right: 60px;
  }
  .sigma_search-form-wrapper .close-btn span {
    height: 40px;
  }
  .sigma_search-form-wrapper form {
    position: relative;
    width: 70%;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  .sigma_search-form-wrapper.open form {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }

  .sigma_search-form-wrapper form input {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #fff;
    padding: 20px 0;
    font-size: 40px;
    width: 100%;
    outline: none;
    color: #fff;
  }
  .sigma_search-form-wrapper form input::placeholder {
    color: #fff;
  }
  .sigma_search-form-wrapper .sigma_search-btn {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    color: #fff;
    font-size: 30px;
    outline: none;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
    padding: 0;
  }
  .sigma_header-custom-link {
    display: flex;
    align-items: center;
  }
  .sigma_header-custom-link i {
    font-size: 20px;
    color: #7e4555;
    margin-right: 10px;
  }

  /*header-light*/
  .header-light .sigma_header-middle .navbar > .navbar-nav > .menu-item > a {
    color: #fff;
  }
  .header-light.sticky
    .sigma_header-middle
    .navbar
    > .navbar-nav
    > .menu-item
    > a {
    color: #db4242;
  }

  .header-light .sigma_header-custom-link {
    color: #fff;
  }

  .header-light .sigma_header-controls-inner li a i {
    color: #fff;
  }

  .header-light .aside-toggler span,
  .header-light .aside-toggler.desktop-toggler span {
    background-color: #fff;
  }

  .header-light
    .sigma_header-middle
    .navbar
    > .navbar-nav
    > .menu-item
    > a:hover,
  .header-light .sigma_header-top-sm li:hover a {
    color: #7e4555;
  }

  /* Mega Menu */
  .navbar-nav .menu-item.mega-menu-wrapper {
    position: static;
  }
  .navbar-nav .menu-item.mega-menu-wrapper .sub-menu li > img {
    position: absolute;
    width: 410px;
    right: 20px;
    bottom: 0;
  }

  .navbar-nav .menu-item.mega-menu-wrapper .sub-menu {
    width: 100%;
  }

  .navbar-nav .menu-item.mega-menu-wrapper .sub-menu li {
    margin: 0;
    padding: 20px;
  }

  .mega-menu-promotion-wrapper {
    margin-top: 30px;
  }

  .navbar-nav .mega-menu-promotion {
    text-align: center;
  }

  .navbar-nav .mega-menu-promotion > a {
    display: block;
    padding: 0;
  }

  .navbar-nav .mega-menu-promotion img {
    width: 150px;
    margin-bottom: 5px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .navbar-nav .mega-menu-promotion:hover img {
    -webkit-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    transform: translateY(-4px);
  }

  .mega-menu-promotion-text h4 {
    margin-bottom: 5px;
    font-size: 18px;
  }

  .mega-menu-promotion-text span {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #848486;
  }

  .navbar-nav .mega-menu-promotion-text h4 a {
    display: block;
    padding: 0;
    color: #db4242;
  }

  .navbar-nav .mega-menu-promotion-text h4 a:hover {
    color: #ed4e53;
  }

  .navbar-nav .mega-menu-item h6 {
    margin-bottom: 10px;
    font-size: 24px;
  }

  .navbar-nav .mega-menu-item p {
    padding: 10px 0;
  }

  .navbar-nav .mega-menu-item a {
    display: block;
    font-weight: 400;
    padding: 10px 0;
  }

  .navbar-nav .mega-menu-item a:last-child {
    border-bottom: 0;
  }

  .navbar-nav .mega-menu-item a:hover {
    background-color: transparent;
    color: #7e4555;
  }

  .navbar-nav .mega-menu-item .sigma_btn-custom {
    display: inline-block;
    padding: 12px 30px;
    color: #fff;
    font-weight: 600;
  }

  .navbar-nav .mega-menu-item .sigma_btn-custom:hover {
    color: #fff;
    background-color: #ec6a47;
  }

  .navbar-nav .mega-menu-item a.coming-soon {
    color: #f5f5f5;
    cursor: default;
  }

  .navbar-nav .mega-menu-item a.coming-soon span {
    margin-left: 5px;
    font-size: 12px;
  }

  /*sigma_header-controls*/
  .sigma_header-controls {
    display: flex;
    align-items: center;
  }

  .sigma_header-controls ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .sigma_header-controls ul > li {
    margin-top: 0;
  }

  .sigma_header-controls ul > li > a {
    position: relative;
    color: #fff;
    font-size: 15px;
    display: flex;
    align-items: center;
  }

  .sigma_notification-count {
    background: #7e4555;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    border-radius: 50%;
    line-height: normal;
    position: absolute;
    top: -10px;
    right: -10px;
  }

  .sigma_header-contact {
    display: flex;
    align-items: center;
  }
  .sigma_header-contact i {
    font-size: 40px;
    line-height: 1;
    margin-right: 10px;
  }
  .sigma_header-contact span {
    font-size: 12px;
    color: #777;
    text-transform: uppercase;
    font-weight: 600;
  }
  .sigma_header-contact h6 {
    margin: 0;
    line-height: 1;
  }

  /* Mobile sidenav */
  .aside-toggler {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    transition: 0.3s;
  }

  .aside-toggler.desktop-toggler {
    display: flex;
    height: 58px;
    width: 58px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: 0.3s;
    border-radius: 8px;
    border: 1px solid #efefef;
  }

  .aside-toggler.desktop-toggler span {
    background-color: #7e4555;
  }

  .aside-toggler.desktop-toggler:hover {
    background-color: transparent;
  }

  .aside-toggler span {
    display: block;
    margin-bottom: 4px;
    width: 20px;
    height: 1px;
    border-radius: 0;
    background-color: #db4242;
    transition: 0.3s;
    transform-origin: right;
  }
  .aside-toggler span:nth-child(even) {
    width: 30px;
  }

  .sigma_header-controls.style-2 .aside-toggler.style-2,
  .aside-toggler.style-2 {
    display: none;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 4px;
  }
  .sigma_header-controls.style-2 .aside-toggler.style-2.desktop-toggler,
  .aside-toggler.style-2.desktop-toggler {
    display: grid;
  }

  .aside-toggler.style-2 span {
    width: 8px;
    height: 8px;
    margin: 0;
  }
  .aside-toggler.style-2 span:nth-child(2) {
    grid-row: 1;
    grid-column: 2;
  }
  .aside-toggler.style-2 span:nth-child(3) {
    grid-row: 1;
    grid-column: 3;
  }
  .aside-toggler.style-2 span:nth-child(4) {
    grid-row: 3;
    grid-column: 1;
  }
  .aside-toggler.style-2 span:nth-child(5) {
    grid-row: 3;
    grid-column: 3;
  }
  .aside-toggler.style-2 span:nth-child(6) {
    grid-row: 3;
    grid-column: 1;
  }
  .aside-toggler.style-2 span:nth-child(7) {
    grid-row: 3;
    grid-column: 2;
  }
  .aside-toggler.style-2 span:nth-child(8) {
    grid-row: 2;
    grid-column: 3;
  }

  .aside-toggler.style-2:hover span:nth-child(2),
  .aside-toggler.style-2:hover span:nth-child(7),
  .aside-toggler.style-2:hover span:nth-child(9),
  .aside-toggler.style-2:hover span:nth-child(8) {
    opacity: 0.4;
  }

  .aside-toggler.style-2:hover span {
    width: 8px;
    height: 8px;
    background-color: #7e4555;
  }

  .sigma_aside {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100%;
    background-color: #fff;
    z-index: 999;
    transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    overflow: auto;
  }

  .sigma_aside-overlay.aside-trigger-left,
  .sigma_aside.sigma_aside-left {
    display: none;
  }

  .sigma_aside.sigma_aside-right {
    left: auto;
    right: -400px;
    padding: 30px;
    width: 400px;
  }

  .sigma_aside.sigma_aside-bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -1;
    background-position: 0 130%;
    background-repeat: no-repeat;
  }

  .sigma_aside.sigma_aside-right .sidebar {
    display: none;
  }

  .sigma_aside.open {
    left: 0;
  }

  .sigma_aside.sigma_aside-right.open {
    left: auto;
    right: 0;
  }

  .sigma_aside.open + .sigma_aside-overlay {
    opacity: 1;
    visibility: visible;
  }

  .sigma_aside-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.7);
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
  }

  .sigma_aside ul {
    margin: 0;
  }

  .sigma_aside ul .menu-item {
    margin: 0;
  }

  .sigma_aside ul .menu-item a {
    padding: 10px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #db4242;
    font-weight: 700;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .sigma_aside ul .menu-item a:hover,
  .sigma_aside ul .menu-item a.active {
    color: #7e4555;
  }

  .navbar-nav .menu-item-has-children > a::after,
  .sigma_aside ul .menu-item.menu-item-has-children > a::after {
    font-family: "Font Awesome 5 Pro";
    content: "\f067";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1.7;
    margin-left: auto;
    font-size: 10px;
  }
  .navbar-nav .menu-item-has-children > a::after {
    margin-left: 10px;
  }
  .navbar-nav .sub-menu .menu-item-has-children > a::after {
    content: "\f054";
  }

  .sigma_aside ul .menu-item .sub-menu {
    display: none;
    background-color: #f7f7f7;
  }

  .sigma_aside .navbar-brand {
    padding: 10px 15px;
    display: block;
    width: 200px;
    margin: 0;
  }

  .sigma_aside.sigma_aside-right .sidebar-widget .widget-title {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 20px;
    font-size: 22px;
  }

  .sigma_aside.sigma_aside-right
    .sidebar-widget.widget-categories-icons
    + .sidebar-widget {
    margin-top: 0;
  }
  .sigma_aside.sigma_aside-right .sidebar-widget {
    padding: 0;
    border: 0;
    box-shadow: none;
    background-color: transparent;
  }

  /* -- Aside trigger Hover sequence -- */
  .aside-toggler:hover span {
    width: 30px;
  }

  /* Header Cart */
  .sigma_cart-sidebar-wrapper.sigma_aside {
    padding: 0;
    width: 60vw;
    max-width: 1200px;
    right: -1200px;
  }
  .sigma_cart-sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .sigma_cart-sidebar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 40px;
    text-transform: uppercase;
  }
  .sigma_cart-sidebar-body {
    position: relative;
    padding: 0 40px;
    height: calc(100% - 245px);
    overflow-y: auto;
  }

  .sigma_cart-sidebar-header .close-btn span {
    height: 30px;
    opacity: 1;
  }
  .sigma_cart-sidebar-header h4 {
    margin: 0;
    font-size: 30px;
  }
  .sigma_cart-sidebar-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    background-color: #f9f9f9;
    padding: 40px;
    margin-top: auto;
  }
  .sigma_cart-sidebar-footer h4 {
    margin: 0;
    font-size: 20px;
  }
  .sigma_cart-sidebar-footer h4 span {
    font-size: 28px;
    margin-left: 10px;
  }

  .cart-open .sigma_cart-sidebar-wrapper.sigma_aside {
    right: 0;
  }
  .cart-open .sigma_aside-overlay.sigma_cart-sidebar-overlay {
    opacity: 1;
    visibility: visible;
  }

  .sigma_cart-sidebar-item {
    position: relative;
    padding: 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 2px dashed #9f9e9e;
  }
  .sigma_cart-sidebar-item:last-child {
    border-bottom: 0;
  }
  .sigma_cart-sidebar-item-body {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 270px;
    display: -ms-flexbox;
    display: flex;
  }
  .sigma_cart-sidebar-item-body img {
    margin-right: 15px;
    width: 90px;
  }
  .sigma_cart-sidebar-item-body-inner {
    flex: 1;
  }
  .sigma_cart-sidebar-item-body-inner h5 {
    margin-bottom: 10px;
  }

  /* 2.1. Header Style 2 */
  .header-2 .sigma_header-middle .navbar {
    align-items: unset;
  }

  .header-2 .sigma_header-controls.style-2 {
    padding: 0 30px;
    border-right: 1px solid #efefef;
  }

  .header-2 .sigma_header-controls.style-1 a {
    padding: 0 30px;
    border-left: 1px solid #efefef;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 30px;
    justify-content: center;
  }

  .header-2 .sigma_header-controls .sigma_btn-custom {
    margin-left: 30px;
  }

  .header-2 .sigma_header-middle .navbar-nav {
    margin-left: 50px;
    margin-right: auto;
  }
  .header-2 .sigma_header-middle .sigma_header-button {
    margin-right: 50px;
    margin-left: auto;
  }

  /* 2.2. Header Style 3 */

  .header-absolute.sigma_header.header-3 {
    padding: 15px;
  }
  .sigma_header.header-3 .sigma_header-middle {
    background-color: #fff;
  }

  .header-3 .sigma_header-middle .navbar {
    align-items: unset;
  }

  .header-3 .sigma_logo-wrapper {
    border-right: 1px solid #efefef;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-3 .sigma_logo-wrapper .navbar-brand {
    margin: 0;
  }

  .header-3 .sigma_header-controls {
    padding: 20px;
    border-left: 1px solid #efefef;
  }

  .sigma_header-inner {
    flex: 1;
  }
  .sigma_header-inner ul > li > a {
    color: #000000;
    padding: 20px 15px;
    text-transform: uppercase;
    font-size: 13px;
    position: relative;
    display: block;
    font-weight: 600;
  }

  .sigma_header-inner .navbar-nav,
  .sigma_header-inner .sigma_header-top {
    padding: 0 20px;
  }

  /* 2.1. Header Style 4 */
  .sigma_header.header-4.header-absolute {
    padding: 0;
  }
  .sigma_header.header-4.header-absolute .sigma_header-top {
    border-bottom: 0;
  }

  .sigma_header.header-4 .sigma_header-top-inner {
    align-items: center;
    justify-content: space-between;
  }

  .sigma_header.header-4 .sigma_header-middle .navbar {
    padding: 0 20px;
    background-color: #fff;
    box-shadow: 0px 10px 20px 0px rgba(53, 82, 99, 0.09);
  }

  .sigma_header.header-4 .sigma_header-button {
    margin-left: auto;
    margin-right: 30px;
  }

  .sigma_header.header-4 .sigma_header-middle .navbar-nav {
    margin-left: 20px;
  }
  .sigma_header.header-4 .sigma_sm li a {
    color: #777;
  }
  .sigma_header.header-4 .sigma_sm li a:hover {
    color: #7e4555;
  }

  .sigma_header.header-4 .sigma_header-top-links a {
    color: #db4242;
    font-weight: 600;
  }
  .sigma_header.header-4 .sigma_header-top-links a:hover {
    color: #7e4555;
  }

  /*======================
  3. Footer
  ========================*/

  .sigma_footer {
    position: relative;
    background-color: #f7f7f7;
    padding: 90px 0 0;
    overflow: hidden;
    z-index: 1;
    font-weight: 600;
  }

  .sigma_footer p {
    margin-bottom: 10px;
    color: #515151;
    font-weight: 600;
  }

  .sigma_footer-top {
    margin-bottom: 50px;
  }
  .sigma_footer-top .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .sigma_footer-middle a,
  .sigma_footer-middle i {
    color: #515151;
  }

  .sigma_footer-middle a:hover {
    color: #7e4555;
  }

  .sigma_footer-middle a.sigma_btn-custom,
  .sigma_footer-middle a.sigma_btn-custom:hover {
    color: #fff;
  }
  .sigma_footer-bottom {
    border-top: 1px solid #efefef;
    padding: 30px 0;
    position: relative;
  }

  .sigma_footer-bottom .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sigma_footer-bottom .sigma_footer-logo {
    background-color: #fff;
    height: 120px;
    padding: 0 40px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
    align-items: center;
  }

  .sigma_footer-bottom .sigma_footer-copyright p {
    margin: 0;
    text-transform: uppercase;
  }

  .sigma_footer-bottom .sigma_footer-copyright > a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #db4242;
    font-size: 14px;
    font-weight: 600;
  }

  .sigma_footer-bottom .sigma_footer-copyright > a:hover {
    color: #7e4555;
  }

  .footer-widget {
    padding-bottom: 80px;
  }
  .footer-widget .sigma_sm {
    margin-top: 25px;
  }
  .footer-widget .sigma_sm li {
    margin: 0;
  }
  .footer-widget .sigma_sm li + li {
    margin-left: 20px;
  }
  .footer-widget .sigma_sm li a {
    transform: translateX(0);
    font-size: 18px;
  }
  .footer-widget .sigma_sm li a i {
    color: #f5f5f5;
    transition: 0.3s;
  }
  .footer-widget .sigma_sm li a:hover i {
    color: #fff;
  }
  .footer-widget .sigma_sm li a::before {
    display: none;
  }

  .footer-widget .widget-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
  }

  .footer-widget ul {
    margin: 0;
  }
  .footer-widget ul li {
    margin: 0;
    transition: 0.3s;
  }
  .footer-widget ul li + li {
    margin: 15px 0 0;
  }

  .footer-widget ul li a {
    position: relative;
    display: inline-block;
    font-weight: 600;
  }
  .footer-widget h5 a {
    position: relative;
  }

  .footer-widget ul li a i {
    font-weight: 400;
  }

  .footer-widget ul.social-media li {
    display: inline-block;
    margin-top: 0;
  }

  .footer-widget ul.social-media li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    font-size: 18px;
    border: 0;
    color: #fff;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footer-widget ul.social-media li a::before {
    display: none;
  }

  .footer-widget ul.social-media li a i {
    color: #fff;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .footer-widget ul.social-media li a:hover i {
    color: #fff;
  }

  .sigma_footer-offer {
    margin-top: 15px;
  }

  .sigma_footer-offer p {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 15px;
  }

  .sigma_footer-offer .btn-custom,
  .sigma_footer-offer .btn-custom:hover {
    color: #fff;
  }

  .sigma_instagram {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
  }

  .sigma_ig-item {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0 2px 5px;
    border-radius: 10px;
  }
  .sigma_ig-item::before {
    content: "\f16d";
    font-family: "Font Awesome 5 Brands";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 3;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    color: #7e4555;
    width: 40px;
    height: 40px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
  }
  .sigma_ig-item:hover::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
  }
  .sigma_ig-item:hover img {
    transform: scale(1.1);
    filter: blur(1px);
  }

  .sigma_ig-item img {
    transition: 0.3s;
    width: 100%;
  }

  /* Footer 2 */
  .sigma_footer.footer-2 {
    padding: 0;
    background-color: #fff;
  }
  .sigma_footer.footer-2 .sigma_footer-top {
    margin-bottom: 0;
  }
  .sigma_footer.footer-2 .sigma_footer-top-item {
    padding: 25px 15px;
    flex: 1;
    display: flex;
    align-items: center;
  }
  .sigma_footer.footer-2 .sigma_footer-top-item + .sigma_footer-top-item {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
  .sigma_footer.footer-2 .sigma_footer-top.secondary-bg .sigma_sm li a {
    color: #fff;
    opacity: 0.8;
    font-size: 15px;
    display: block;
  }
  .sigma_footer.footer-2 .sigma_footer-sm {
    justify-content: flex-end;
  }
  .sigma_footer.footer-2 .sigma_footer-top.secondary-bg .sigma_sm li a:hover {
    color: #7e4555;
    opacity: 1;
  }

  .sigma_footer.footer-2 .sigma_footer-newsletter form {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .sigma_footer.footer-2 .sigma_footer-newsletter form::before {
    content: "\f0e0";
    font-family: "Font Awesome 5 Pro";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    color: rgba(255, 255, 255, 0.8);
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }
  .sigma_footer.footer-2 .sigma_footer-newsletter form input {
    background: no-repeat;
    border: 0;
    color: #fff;
    padding: 9px 25px 9px 10px;
    flex: 1;
    outline: none;
  }
  .sigma_footer.footer-2 .sigma_footer-newsletter form button:focus,
  .sigma_footer.footer-2 .sigma_footer-newsletter form input:focus {
    outline: none;
  }
  .sigma_footer.footer-2 .sigma_footer-newsletter form input::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .sigma_footer.footer-2 .sigma_footer-newsletter form button {
    background: none;
    padding: 0;
    border: 0;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 14px;
    float: right;
    cursor: pointer;
    outline: none;
    display: inline-flex;
    align-items: center;
  }
  .sigma_footer.footer-2 .sigma_footer-newsletter form button i {
    margin-left: 10px;
  }
  .sigma_footer.footer-2 .sigma_footer-newsletter form button::before {
    background-color: #fff;
  }
  .sigma_footer.footer-2 .sigma_footer-middle {
    padding-top: 80px;
  }

  .sigma_footer.footer-2 .footer-widget ul li i {
    margin-right: 5px;
    color: #db4242;
  }

  .sigma_footer.footer-2 .footer-widget ul li:hover {
    transform: translateX(4px);
  }

  .sigma_footer .sigma_footer-bottom ul li a {
    font-weight: 400;
  }

  .sigma_footer.sigma_footer-dark .footer-widget i.custom-primary {
    color: #fff !important;
  }

  .sigma_footer.footer-2.sigma_footer-dark
    .widget-recent-posts
    .sigma_recent-post
    h6
    a {
    color: #ccc;
  }

  .sigma_footer.footer-2.sigma_footer-dark
    .widget-recent-posts
    .sigma_recent-post
    h6
    a:hover {
    color: #fff;
  }

  .sigma_footer.footer-2.sigma_footer-dark
    .widget-recent-posts
    .sigma_recent-post-body
    > a,
  .sigma_footer.footer-2.sigma_footer-dark
    .widget-recent-posts
    .sigma_recent-post-body
    > a
    i {
    color: #fff;
  }

  .sigma_footer.footer-2.sigma_footer-dark .sigma_footer-bottom ul li a:hover {
    background-color: #fff;
    color: #7e4555;
  }

  /* Dark Footer */
  .sigma_footer.sigma_footer-dark {
    background-color: #7e4555;
    color: #fff;
  }
  .sigma_footer.sigma_footer-dark.primary-bg,
  .sigma_footer.primary-bg {
    background-color: #7e4555;
  }
  .sigma_footer.sigma_footer-dark .sigma_footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sigma_footer.sigma_footer-dark .footer-widget ul li a::before,
  .sigma_footer.sigma_footer-dark .sigma_footer-bottom ul li a:hover,
  .sigma_footer.sigma_footer-dark .footer-widget ul li a:hover,
  .sigma_footer.sigma_footer-dark
    .sigma_footer-bottom
    .sigma_footer-copyright
    > a,
  .sigma_footer.sigma_footer-dark .footer-widget .widget-title {
    color: #fff;
  }

  .sigma_footer.sigma_footer-dark p,
  .sigma_footer.sigma_footer-dark .footer-widget ul li a {
    color: #ccc;
  }

  /*======================
  5. Subheader
  ========================*/

  .sigma_subheader {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
  }

  .sigma_subheader-inner {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header-absolute + .sigma_subheader .sigma_subheader-inner {
    padding: 270px 0 150px;
  }
  .header-absolute + .sigma_subheader.subheader-lg .sigma_subheader-inner {
    padding: 230px 0 340px;
  }

  .sigma_subheader h1 {
    font-size: 60px;
    margin-bottom: 0;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .sigma_subheader .blockquote {
    max-width: 500px;
  }

  .sigma_subheader .breadcrumb {
    padding: 20px 28px;
    margin: 0;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 10px 20px 0px rgb(53 82 99 / 9%);
  }

  .sigma_subheader .breadcrumb li {
    margin: 0;
    color: #f1f1f1;
  }
  .sigma_subheader .breadcrumb-item + .breadcrumb-item {
    padding-left: 15px;
  }

  .sigma_subheader .breadcrumb li a {
    position: relative;
    color: #777;
    font-weight: 700;
    font-size: 14px;
  }
  .sigma_subheader .breadcrumb li a::before {
    background-color: #fff;
  }

  .sigma_subheader .breadcrumb li a:hover {
    color: #777;
  }

  .sigma_subheader .breadcrumb .breadcrumb-item.active {
    color: #7e4555;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .sigma_subheader .breadcrumb-item + .breadcrumb-item::before {
    color: #777;
    font-family: "Font Awesome 5 Pro";
    content: "\f679";
    margin-left: auto;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    padding-right: 15px;
    font-size: 16px;
    line-height: 1.7;
  }

  .sigma_subheader .sigma_sm {
    justify-content: flex-end;
  }
  .sigma_subheader .sigma_sm li a {
    color: #777;
  }
  .sigma_subheader .sigma_sm li a:hover {
    color: #fff;
  }

  .vertical-toggler {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }
  .vertical-toggler span {
    display: block;
    height: 20px;
    width: 3px;
    margin: 0 2px;
    transition: 0.3s;
    background-color: #7e4555;
  }
  .vertical-toggler span:nth-child(odd) {
    height: 30px;
  }
  .vertical-toggler:hover span {
    height: 30px;
  }
  .vertical-toggler:hover span:nth-child(odd) {
    height: 20px;
  }

  .sigma_subheader-extras {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0;
    visibility: hidden;
    padding: 30px;
    transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    z-index: 49;
  }
  .sigma_subheader-extras .sigma_map {
    height: 300px;
  }
  .sigma_subheader-extras.open {
    height: 50%;
    opacity: 1;
    visibility: visible;
  }
  .sigma_subheader-extras .close-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    border: 1px solid #272727;
  }
  .sigma_subheader-extras .close-btn span {
    height: 25px;
  }

  .sigma_subheader-extras .sigma_contact-info-item + .sigma_contact-info-item {
    margin-top: 20px;
    padding-top: 0;
    border-top: 0;
  }

  /*======================
  6. Components
  ========================*/

  /* 6.1. Blog Posts */
  .sigma_post {
    position: relative;
    margin-bottom: 30px;
  }

  .sigma_post .sigma_post-thumb,
  .gallery-thumb {
    position: relative;
    display: block;
    overflow: hidden;
  }
  .entry-content .gallery-thumb {
    margin-bottom: 20px;
  }
  .entry-content .gallery-thumb img {
    margin: 0;
  }
  .gallery-thumb img,
  .sigma_post .sigma_post-thumb img {
    width: 100%;
    transition: 0.3s;
  }

  .sigma_post .sigma_post-thumb:hover img,
  .gallery-thumb:hover img {
    transform: scale(1.2);
  }

  .sigma_post .event-date-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 140px;
    height: 140px;
    background-color: #db4242;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .sigma_post .event-date-wrapper span {
    display: block;
    line-height: 1;
    margin-bottom: 4px;
    color: #7e4555;
    font-size: 40px;
  }

  .sigma_post.event-list .sigma_post-body .sigma_post-meta {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    margin-top: 25px;
  }

  .sigma_post.event-list {
    border-radius: 10px;
    overflow: hidden;
  }

  .sigma_post.event-list .sigma_post-body .sigma_post-meta a {
    font-size: 14px;
  }

  .sigma_post.event-list .sigma_post-body .sigma_post-meta a + a {
    margin-left: 0;
    margin-top: 10px;
  }

  .entry-content .sigma_event-timer {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: rgba(33, 33, 33, 0.8);
    width: 100%;
    display: flex;
    text-align: center;
  }

  .entry-content .sigma_event-timer .sigma_event-date {
    width: 14%;
    padding: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }

  .entry-content .sigma_event-timer .sigma_event-date span {
    display: block;
    color: #7e4555;
    font-size: 30px;
    margin-bottom: -6px;
  }

  .entry-content .sigma_event-timer .sigma_countdown-timer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: transparent;
    border: 0;
    padding: 10px;
    margin: 0;
    width: 84%;
  }

  .entry-content .sigma_event-timer .sigma_countdown-timer li {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .entry-content .sigma_event-timer .sigma_countdown-timer h5 {
    color: #fff;
    margin-bottom: 0;
  }

  .entry-content .sigma_event-timer .sigma_countdown-timer li span {
    color: #fff;
    font-size: 16px;
    margin-top: 5px;
  }

  .gallery-thumb {
    z-index: 1;
  }

  .gallery-thumb .caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    text-transform: uppercase;
    color: #fff;
    font-size: 11px;
    letter-spacing: 1.4px;
    z-index: 3;
  }
  .gallery-thumb.lg .caption {
    font-size: 18px;
  }

  .sigma_post .sigma_post-body {
    padding: 30px;
    border: 1px solid #efefef;
    position: relative;
    background-color: #fff;
    z-index: 1;
  }

  .sigma_post-meta {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .sigma_post-meta i {
    margin-right: 5px;
    color: #7e4555;
  }

  .sigma_post-meta a,
  .sigma_post-date {
    font-size: 13px;
    color: #db4242;
    font-weight: 600;
  }
  .sigma_post-meta a:hover {
    color: #7e4555;
  }

  .sigma_post-categories {
    margin-right: 20px;
  }

  .sigma_post-categories a {
    background-color: #efefef;
    display: inline-block;
    padding: 4px 30px;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 1.7;
    border-radius: 50px;
    margin: 0 3px 3px 0;
    background-color: #7e4555;
  }

  .sigma_post-categories a:hover {
    background-color: #ec6a47;
    color: #fff;
  }

  .sigma_post-date span {
    color: #db4242;
    transition: 0.3s;
    font-size: 18px;
    font-weight: 800;
  }
  .sigma_post-date:hover span {
    color: #ec6a47;
  }
  .sigma_post-date i {
    margin-right: 5px;
  }

  .sigma_post .sigma_post-body h5 {
    margin: 0 0 15px;
    word-break: break-word;
    font-size: 18px;
    margin-bottom: 0;
  }
  .sigma_post .sigma_post-body h5 + p {
    margin: 20px 0 0;
  }

  .sigma_post .sigma_post-body h5 a {
    color: #db4242;
  }

  .sigma_post .sigma_post-body h5 a:hover,
  .sigma_post .sigma_post-body .sigma_post-desc > span a:hover {
    color: #ec6a47;
  }

  .sigma_post .sigma_post-body .sigma_post-desc > span {
    display: block;
    color: #db4242;
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 10px;
  }

  .sigma_post .sigma_post-body .sigma_post-desc > span a {
    color: #7e4555;
  }

  .sigma_post .sigma_post-body .sigma_post-desc p {
    margin-bottom: 20px;
  }

  /* 6.1.1. Blog Posts list */
  .sigma_post.sigma_post-list .sigma_post-body {
    padding: 40px;
  }
  .sigma_post.sigma_post-list .sigma_post-single-author {
    margin-top: 0;
  }

  .sigma_post.sigma_post-list .sigma_post-body h5 {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .sigma_post.sigma_post-list .sigma_post-meta {
    margin-bottom: 15px;
  }

  .sigma_post.sigma_post-list .sigma_post-meta a + a {
    margin-left: 20px;
  }
  .sigma_post.sigma_post-list .sigma_post-footer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /* Formats */
  .sigma_post.post-format-video .sigma_post-thumb .sigma_video-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .sigma_post.post-format-audio .embed-responsive-16by9::before {
    padding-top: 16.25%;
  }
  .sigma_post.post-format-quote {
    background-color: #7e4555;
  }

  .sigma_post.post-format-quote h5 a,
  .sigma_post.post-format-quote * {
    color: #fff;
  }

  .sigma_post.post-format-quote .sigma_post-meta a:hover,
  .sigma_post.post-format-quote h5 a:hover,
  .sigma_post.post-format-quote h5 a:hover {
    color: #db4242;
  }
  .sigma_post.post-format-quote .sigma_post-body {
    padding: 40px;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: flex-start;
  }
  .sigma_post.post-format-quote .sigma_post-body > i {
    margin-right: 20px;
    font-size: 70px;
    line-height: 1;
  }
  .sigma_post.post-format-quote .sigma_post-meta {
    margin: 0;
  }

  .sigma_post.post-format-image .sigma_post-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .sigma_post.post-format-image .sigma_post-body {
    background-color: transparent;
  }
  .sigma_post.post-format-image .sigma_post-body p {
    margin: 0;
  }
  .sigma_post.post-format-image .sigma_post-body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(255, 255, 255, 0.9);
  }

  /* Style 2 */
  .sigma_post.style-2 .sigma_post-body {
    border: 0;
    border-top: 3px solid #7e4555;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: -45px;
  }

  /* 6.1.2. Blog Post Details */

  .sigma_post-single .section {
    padding: 0;
  }
  .sigma_post-single .section + .section {
    padding-top: 50px;
  }
  .post-detail-wrapper {
    padding: 40px;
    border-radius: 10px;
    border: 2px solid #efefef;
  }

  .post-detail-wrapper .sigma_post-meta a + a {
    margin-left: 20px;
  }
  .post-detail-wrapper .sigma_post-meta {
    margin-bottom: 20px;
  }

  .comments-list ul {
    margin: 0;
  }
  .comment-form {
    padding: 40px;
    background-color: #f7f7f7;
  }
  .comments-list .comment-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 30px;
    margin-top: 0;
  }
  .comments-list .comment-item + .comment-item {
    border-top: 1px solid #efefef;
    padding-top: 30px;
  }

  .comments-list .comment-item:last-child {
    margin-bottom: 0;
  }

  .comments-list .comment-item img {
    width: 100px;
    margin-right: 20px;
    border-radius: 50%;
  }

  .comments-list .comment-item .comment-body {
    position: relative;
    flex: 1;
  }

  .comments-list .comment-item h5 {
    margin-bottom: 15px;
    font-size: 18px;
  }

  .comments-list .comment-item span {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 15px;
    line-height: 21px;
    color: #7e4555;
    font-weight: 600;
  }

  .comments-list .comment-item .btn-link {
    padding: 10px 25px;
    border: 2px solid #efefef;
    border-radius: 25px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .comments-list .comment-item p {
    margin-bottom: 15px;
  }

  .reply-link {
    position: relative;
    color: #db4242;
    font-weight: 600;
  }

  .sigma_post-single-thumb {
    position: relative;
    margin-bottom: 30px;
  }
  .sigma_post-single-thumb img {
    margin: 0;
  }

  .sigma_post-single-author {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .sigma_post-single-author img {
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 0;
  }
  .sigma_post-single-author-content {
    display: flex;
    align-items: center;
  }
  .sigma_post-single-author-content p {
    font-weight: 600;
    color: #db4242;
    margin-bottom: 0;
    margin-left: 5px;
  }

  .sigma_post-single-author-content span {
    font-size: 13px;
  }

  .sigma_author-about {
    display: flex;
    align-items: center;
    padding: 30px;
    border-radius: 10px;
    border: 2px solid #efefef;
  }
  .sigma_author-about img {
    width: 150px;
    margin-right: 30px;
  }
  .sigma_author-about .sigma_author-about-content {
    flex: 1;
  }
  .sigma_author-about .sigma_author-about-content span {
    color: #7e4555;
    font-weight: 600;
    text-transform: uppercase;
  }
  .sigma_author-about .sigma_author-about-content h3 {
  }
  .sigma_author-about .sigma_author-about-content p {
    margin: 0;
  }

  .sigma_post-single-meta {
    display: flex;
    align-items: center;
    padding: 0 0 50px;
    margin-top: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .sigma_post-single-meta-item {
    flex: 1;
  }

  .sigma_post-single-meta-item h6 {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .sigma_post-share .sigma_sm li {
    margin-top: 6px;
  }
  .sigma_post-share .sigma_sm li + li {
    margin-left: 15px;
  }
  .sigma_post-single-meta-item.sigma_post-share {
    text-align: right;
  }
  .sigma_post-single-meta-item.sigma_post-share .sigma_sm {
    justify-content: flex-end;
  }

  .sigma_post-single-meta-item .sigma_product-controls {
    position: relative;
    transform: translate(0, 0);
    top: auto;
    left: auto;
    opacity: 1;
    visibility: visible;
    justify-content: flex-end;
  }
  .sigma_post-single-meta-item .sigma_product-controls a {
    background-color: #f7f7f7;
  }
  .sigma_product-single-content .sigma_post-single-meta-item.sigma_post-share {
    text-align: left;
  }

  .sigma_product-single-content
    .sigma_post-single-meta-item.sigma_post-share
    .sigma_sm {
    justify-content: flex-start;
  }

  .sigma_post-share .sigma_sm li a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sigma_post-share .sigma_sm li a i {
    color: #777;
  }

  .sigma_single-pagination {
    margin: 30px 0;
  }

  .sigma_single-pagination-item + .sigma_single-pagination-item {
    margin-top: 10px;
  }

  .sigma_single-pagination-thumb {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
    background-color: #fff;
    border-radius: 0;
    margin-right: 10px;
  }

  .sigma_single-pagination a {
    display: flex;
    align-items: center;
  }

  .sigma_single-pagination a h6 {
    margin: 0;
    font-size: 14px;
  }

  .sigma_single-pagination a img {
    max-width: 40px;
  }

  .sigma_single-pagination-prev,
  .sigma_single-pagination-next {
    padding: 10px;
    border-radius: 0;
    background-color: #fff;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
    transition: 0.3s;
  }

  .sigma_single-pagination-prev:hover,
  .sigma_single-pagination-next:hover {
    background-color: #f7f7f7;
  }

  .sigma_single-pagination i {
    color: #777;
    margin-left: auto;
    margin-right: 20px;
    transition: 0.3s;
  }

  .sigma_single-pagination-prev:hover i {
    transform: translateX(-3px);
  }

  .sigma_single-pagination-next:hover i {
    transform: translateX(3px);
  }

  /* 6.2. Sidebar */
  .sidebar-widget {
    padding: 30px;
    border-radius: 10px;
    border: 2px solid #efefef;
    background-color: #fff;
  }

  .sidebar-widget + .sidebar-widget {
    margin-top: 30px;
  }

  .sidebar-widget .widget-title {
    position: relative;
    padding-left: 45px;
    margin-bottom: 30px;
    font-size: 22px;
  }
  .sidebar-widget .widget-title::before {
    content: "";
    width: 20px;
    height: 3px;
    border-radius: 4px;
    background-color: #7e4555;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  .sidebar-widget .widget-title::after {
    content: "";
    width: 5px;
    height: 3px;
    border-radius: 4px;
    background-color: #7e4555;
    display: block;
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
  }

  .sidebar-widget ul.sidebar-widget-list {
    margin: 0;
    padding: 0;
  }

  .sidebar-widget ul.sidebar-widget-list ul {
    padding-left: 20px;
  }

  .sidebar-widget ul.sidebar-widget-list li {
    margin: 0;
  }

  .sidebar-widget ul.sidebar-widget-list li ul li:first-child {
    margin-top: 20px;
  }

  .sidebar-widget ul.sidebar-widget-list li + li {
    margin-top: 20px;
  }

  .sidebar-widget ul.sidebar-widget-list li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #777;
  }

  .sidebar-widget ul.sidebar-widget-list li a:hover {
    color: #7e4555;
  }

  .widget-about-author-inner {
    text-align: center;
  }
  .widget-about-author-bg {
    height: 150px;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
  }

  .widget-about-author-inner img {
    border-radius: 50%;
    width: 150px;
    margin: 0 auto 20px;
  }

  .widget-about-author-inner h5 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .widget-about-author-inner .sigma_sm {
    justify-content: center;
  }
  .widget-about-author-inner .sigma_sm li a {
    color: #777;
  }
  .widget-about-author-inner .sigma_sm li a:hover {
    color: #7e4555;
  }

  .widget-recent-posts .sigma_recent-post {
    display: flex;
    align-items: center;
  }

  .widget-recent-posts .sigma_recent-post > a {
    position: relative;
    display: block;
    overflow: hidden;
    margin-right: 20px;
    width: 75px;
  }

  .widget-recent-posts .sigma_recent-post > a img {
    transition: 0.3s;
  }

  .widget-recent-posts .sigma_recent-post > a img:hover {
    transform: scale(1.1) rotate(2deg);
  }

  .sidebar-widget.widget-recent-posts .sigma_recent-post > a img {
    border-radius: 50%;
  }
  .sidebar-widget.widget-recent-posts .sigma_recent-post h6 a {
    color: #db4242;
  }
  .sidebar-widget.widget-recent-posts .sigma_recent-post h6 a:hover {
    color: #7e4555;
  }

  .sidebar-widget.widget-recent-posts .sigma_recent-post + .sigma_recent-post {
    padding-top: 20px;
    border-top: 1px solid #efefef;
  }

  .widget-recent-posts .sigma_recent-post-body {
    flex: 1;
  }
  .widget-recent-posts .sigma_recent-post-body > a {
    color: #707070;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
  }
  .widget-recent-posts .sigma_recent-post-body > a i {
    margin-right: 5px;
    color: #7e4555;
  }
  .widget-recent-posts .sigma_recent-post-body > a:hover {
    color: #ec6a47;
  }

  .widget-recent-posts .sigma_recent-post h6 {
    margin-bottom: 6px;
    line-height: 1.2;
    font-size: 14px;
  }

  .widget-recent-posts .sigma_recent-post h6 a {
    color: #fff;
  }
  .sigma_footer:not(.sigma_footer-dark)
    .widget-recent-posts
    .sigma_recent-post
    h6
    a {
    color: #db4242;
  }
  .sigma_footer:not(.sigma_footer-dark) .sigma_sm.square li a {
    background-color: #f7f7f7;
    color: #db4242;
  }
  .sigma_footer:not(.sigma_footer-dark) .sigma_sm.square li a:hover {
    background-color: #7e4555;
    color: #fff;
  }

  .sigma_footer:not(.sigma_footer-dark)
    .widget-recent-posts
    .sigma_recent-post
    h6
    a:hover,
  .widget-recent-posts .sigma_recent-post h6 a:hover,
  .widget-recent-posts .sigma_recent-post span a:hover {
    color: #7e4555;
  }

  .widget-recent-posts .sigma_recent-post span {
    font-size: 13px;
    line-height: 1.7;
    font-weight: 600;
    color: #db4242;
  }

  .widget-recent-posts .sigma_recent-post span a {
    font-weight: 400;
    text-decoration: underline;
    color: #db4242;
  }

  .widget-recent-posts .sigma_recent-post + .sigma_recent-post {
    margin-top: 20px;
  }

  .sidebar-widget.widget-logo img {
    width: 180px;
  }

  .sidebar-widget.widget-categories ul.sidebar-widget-list ul {
    padding: 0;
  }

  .sidebar-widget.widget-categories ul li a {
    padding: 15px;
    background-color: #f7f7f7;
    color: #777;
    border-radius: 50px;
    font-weight: 600;
  }

  .sidebar-widget.widget-categories ul li a span {
    border-radius: 50%;
    background-color: #7e4555;
    color: #fff;
    font-size: 13px;
    width: 30px;
    transition: 0.3s;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar-widget.widget-categories ul li a:hover {
    background-color: #7e4555;
    color: #fff;
  }

  .sidebar-widget.widget-categories ul li a:hover span {
    background-color: #f0fff0;
    color: #7e4555;
  }

  .sidebar-widget.event-info li + li {
    margin-top: 15px;
  }

  .sidebar-widget.event-info li span {
    display: block;
    width: 43%;
    float: left;
    font-weight: 600;
  }

  .sidebar-widget.widget-speakers .card {
    background-color: #fff;
  }
  .sidebar-widget.widget-speakers .card .card-header {
    background-color: #fff;
    padding: 0 0 20px;
    margin-bottom: 18px;
    border-bottom: 1px solid #efefef;
  }

  .sidebar-widget.widget-speakers .card .card-body {
    padding: 0 0 18px;
  }

  .sidebar-widget.widget-speakers .card .speaker-image {
    margin-right: 15px;
    border-radius: 50%;
  }

  .sidebar-widget.widget-speakers .card .speaker-image img {
    border-radius: 50%;
  }

  .sidebar-widget.widget-speakers .card h6 {
    font-size: 18px;
  }
  .sidebar-widget.widget-speakers .accordion .card-header::before,
  .sidebar-widget.widget-speakers .accordion .card-header::after {
    content: none;
  }

  .sidebar-widget.widget-upcoming-events li {
    display: flex;
    align-items: center;
  }

  .sidebar-widget.widget-upcoming-events li + li {
    padding-top: 25px;
    margin-top: 25px;
    border-top: 1px solid #efefef;
  }

  .sidebar-widget.widget-upcoming-events li .date {
    color: #db4242;
    font-weight: 600;
    border-right: 1px solid #efefef;
    padding-right: 20px;
    margin-right: 20px;
  }

  .sidebar-widget.widget-upcoming-events li .date span {
    display: block;
    line-height: 1;
    margin-bottom: 4px;
    color: #7e4555;
    font-weight: 600;
    font-size: 40px;
  }

  .sidebar-widget.widget-upcoming-events li h6 {
    margin-bottom: 3px;
  }

  .sidebar-widget.widget-upcoming-events li p {
    font-size: 16px;
    margin-bottom: 0;
  }

  .tags a,
  .tagcloud a,
  .entry-content .wp-block-tag-cloud a {
    display: inline-block;
    font-size: 12px;
    padding: 3px 12px;
    color: #db4242;
    text-transform: uppercase;
    font-weight: 500;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background-color: #efefef;
    margin: 6px 6px 0 0;
    border-radius: 50px;
  }

  .tags a:hover,
  .tagcloud a:hover,
  .entry-content .wp-block-tag-cloud a:hover {
    background-color: #7e4555;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .twitter-item + .twitter-item {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #efefef;
  }

  .sigma_newsletter-form {
    position: relative;
  }

  .sigma_newsletter-form.primary-bg {
    background-color: #7e4555;
  }

  .sigma_newsletter-form h5 {
    margin-bottom: 15px;
    position: relative;
    font-size: 24px;
  }

  .sigma_countdown-timer {
    text-align: center;
    background-color: #fff;
    border: 1px solid #efefef;
    padding: 10px;
    font-weight: 600;
    margin: 20px 0 0;
    transition: 0.3s;
  }

  /* 6.3. Products */
  .sigma_product {
    position: relative;
    margin-bottom: 30px;
  }

  .sigma_product .sigma_product-thumb {
    position: relative;
    overflow: hidden;
    border: 2px solid #efefef;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .sigma_product .sigma_product-thumb.thumb-sm {
    max-width: 200px;
    margin: 0 auto 20px;
  }

  .sigma_product .sigma_product-thumb img {
    transition: 0.3s;
    width: 100%;
  }

  .sigma_product .sigma_product-thumb:hover img {
    transform: scale(1.1) rotate(3deg);
  }
  .sigma_product .sigma_product-body {
    position: relative;
    text-align: center;
    padding: 20px;
    border: 2px solid #efefef;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top: 0;
  }
  .sigma_product .sigma_product-body h5 {
    margin: 0 0 15px;
    font-size: 18px;
    word-break: break-word;
  }

  .sigma_product .sigma_product-body .sigma_product-title a {
    color: #db4242;
  }

  .sigma_product .sigma_product-body .sigma_product-title a:hover {
    color: #ec6a47;
  }

  .sigma_product .sigma_badge-featured {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
    background-color: #ffa80a;
    font-weight: 600;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.7;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .sigma_product .sigma_badge-featured i {
    display: inline-block;
  }

  .sigma_product .sigma_badge-sale {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 3;
    background-color: #db4242;
    font-weight: 600;
    color: #fff;
    font-weight: 700;
    font-size: 11px;
    line-height: 1.7;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .sigma_product .sigma_rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .sigma_product .sigma_rating-wrapper span {
    font-weight: 600;
  }

  .sigma_product-price {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sigma_product-price span {
    display: block;
  }

  .sigma_product-price span:first-child {
    font-size: 14px;
    line-height: 1.2;
    color: #7e4555;
    font-weight: 700;
  }

  .sigma_product-price span + span {
    font-size: 14px;
    color: #777;
    font-weight: 400;
    line-height: 1;
    margin-left: 10px;
    text-decoration: line-through;
  }

  .sigma_product-stock {
    font-weight: 600;
  }

  .sigma_product-stock.instock {
    color: #58da58;
  }

  .sigma_product-stock.outofstock {
    color: #f54978;
  }

  .sigma_product .sigma_rating {
    margin-bottom: 0;
  }

  .sigma_product-controls {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.3s;
    visibility: hidden;
  }
  .sigma_product:hover .sigma_product-controls {
    opacity: 1;
    visibility: visible;
  }
  .sigma_product-controls a {
    width: 40px;
    height: 40px;
    background-color: #fff;
    color: #777;
  }
  .sigma_product-controls a + a {
    margin-left: 10px;
  }

  .sigma_product-controls a i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
  }

  .sigma_product-controls a:hover {
    background-color: #7e4555;
    color: #fff;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
  }

  .sigma_product-footer {
    display: flex;
    align-items: center;
  }

  .sigma_shop-global {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .sigma_shop-global p {
    color: #db4242;
    font-weight: 600;
    margin-bottom: 0;
  }

  .irs--flat .irs-from,
  .irs--flat .irs-to,
  .irs--flat .irs-single,
  .irs--flat .irs-bar,
  .irs--flat .irs-handle > i:first-child {
    background-color: #7e4555;
  }

  .irs--flat .irs-from:before,
  .irs--flat .irs-to:before,
  .irs--flat .irs-single:before {
    border-top-color: #7e4555;
  }

  .irs--flat .irs-handle.state_hover > i:first-child,
  .irs--flat .irs-handle:hover > i:first-child {
    background-color: #7e4555;
  }

  /* 6.3.3 Products Quick View & Details */
  .sigma_quick-view-modal .sigma_product-single-content {
    padding: 0 40px;
  }

  .sigma_quick-view-modal .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
  }

  .sigma_product-single-content .sigma_rating-wrapper {
    margin-bottom: 0;
  }

  .sigma_product-single-content .sigma_product-excerpt,
  .sigma_product-single-content .sigma_product-variation-wrapper {
    margin-bottom: 25px;
  }

  .sigma_product-single-content .sigma_product-price {
    margin-bottom: 20px;
    justify-content: flex-start;
  }

  .sigma_product-single-content .sigma_product-price span:first-child {
    font-size: 32px;
  }

  .sigma_product-single-content .sigma_product-price span {
    display: inline-block;
  }

  .sigma_product-single-content .sigma_product-price span + span {
    margin-left: 10px;
  }

  .sigma_product-single-content .sigma_post-single-meta {
    border-bottom: 0;
    border-top: 1px solid #efefef;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #efefef;
  }
  .sigma_product-single-content .sigma_post-single-meta + h6 {
    margin: 5px;
  }

  .sigma_product-atc-form .qty-outter {
    display: flex;
    align-items: center;
  }

  .sigma_product-atc-form .qty-outter .sigma_btn-custom + .qty {
    margin-left: 20px;
  }

  .sigma_product-atc-form .qty-inner {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .sigma_product-atc-form .qty-inner h6 {
    text-transform: uppercase;
    margin: 0 10px 0 0;
    font-size: 13px;
  }

  .qty {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
  }

  .qty input {
    border: 0;
    outline: none;
    width: 30px;
    text-align: center;
    font-weight: 600;
    border: 0;
    margin: 0 5px;
  }

  .qty span.qty-subtract {
    border-right: 0;
    cursor: pointer;
  }

  .qty span.qty-add {
    border-left: 0;
    cursor: pointer;
  }

  .qty span:hover {
    color: #7e4555;
  }

  .qty span {
    font-size: 13px;
    transition: 0.3s;
    color: #db4242;
    line-height: 1;
  }

  .sigma_product-radio label {
    cursor: pointer;
    font-weight: 700;
    color: #db4242;
    font-style: normal;
  }

  .sigma_product-radio label input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
  .sigma_product-radio label span {
    position: relative;
    z-index: 1;
    font-size: 13px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sigma_product-radio label span::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    z-index: -1;
    background-color: #7e4555;
  }
  .sigma_product-radio label input:checked + span::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
  }
  .sigma_product-radio label input:checked + span {
    color: #fff;
  }

  .sigma_product-additional-info .nav {
    border-bottom: 1px solid #efefef;
  }

  .sigma_product-additional-info .nav-link {
    margin: 0;
    border-radius: 0;
    font-weight: 600;
    color: #777;
    border-bottom: 2px solid transparent;
  }

  .sigma_product-additional-info .nav-item + .nav-item .nav-link {
    margin-left: 10px;
  }

  .sigma_product-additional-info .nav-item + .nav-item .nav-link:hover {
    color: #7e4555;
  }

  .sigma_product-additional-info .tab-content {
    z-index: 1;
    margin-top: 30px;
  }

  .sigma_product-additional-info .tab-content table {
    margin: 0;
  }

  .sigma_product-additional-info .sigma_rating-wrapper,
  .sigma_product-additional-info .comment-body .sigma_rating {
    margin-bottom: 20px;
  }
  .sigma_product-additional-info .tab-content .comment-form,
  .sigma_product-additional-info .tab-content .comments-list {
    padding: 0;
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .sigma_product-additional-info .tab-content .comments-list .comment-item {
    padding: 0;
    border: 0;
  }
  .sigma_product-additional-info .comment-form {
    margin-bottom: 50px;
  }

  .sigma_product-single-controls {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sigma_product-single-controls .sigma_add-to-favorite {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
    margin: 0 10px 0 0;
  }

  .sigma_product-single-controls .sigma_add-to-favorite:hover {
    background-color: #f7f7f7;
  }

  .sigma_product-single-controls .sigma_add-to-favorite i {
    font-size: 21px;
    color: #777;
  }

  .sigma_product-meta {
    margin: 20px 0 0;
  }

  .sigma_product-single-2 .sigma_product-meta {
    margin: 20px 0 30px;
  }

  .sigma_product-meta li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .sigma_product-meta li > span {
    font-weight: 600;
    color: #db4242;
    width: 120px;
  }

  .sigma_product-meta li .sigma_product-meta-item span,
  .sigma_product-meta li a {
    color: #777;
  }

  .sigma_product-meta li a:hover {
    color: #7e4555;
  }

  .sigma_product-single-thumb,
  .sigma_product-single-zoom > img {
    background-color: #fff;
  }

  .sigma_product-single-thumb .slider {
    margin-bottom: 30px;
  }

  /* 6.3.4. Cart & Wishlist */
  td.remove {
    width: 30px;
  }

  .sigma_cart-product-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .sigma_cart-product-wrapper img {
    width: 50px;
    margin-right: 15px;
  }

  .sigma_cart-product-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .sigma_cart-product-wrapper h6 {
    margin-bottom: 0;
    font-size: 14px;
    transition: 0.3s;
  }

  .sigma_cart-product-wrapper h6 a {
    color: #db4242;
  }

  .sigma_cart-product-wrapper h6 a:hover {
    color: #7e4555;
  }

  .sigma_cart-product-wrapper p {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 21px;
    color: #848486;
  }

  /* 6.3.5. Checkout */
  .sigma_notice {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    border: 2px solid #efefef;
  }

  /* 6.5. Banners */
  .sigma_banner .bottom-skew {
    z-index: 2;
  }
  .sigma_banner video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .banner-1.sigma_banner img {
    position: absolute;
    top: 0;
    right: 0;
  }
  .banner-1.sigma_banner img.left {
    right: auto;
    left: 0;
  }

  /* Style 2 */
  .banner-2.sigma_banner .sigma_banner-slider-inner {
    overflow: hidden;
    position: relative;
    padding: 350px 0 300px;
  }

  .banner-2.sigma_banner .highlight-text {
    background-color: #fff;
    padding: 7px 26px;
    color: #db4242;
    display: inline-flex;
    font-weight: 500;
    margin-bottom: 28px;
  }

  .banner-2.sigma_banner .title {
    font-size: 80px;
    line-height: 1;
  }
  .banner-2.sigma_banner p {
    font-size: 18px;
  }

  /* 6.6. Icon Blocks */
  .sigma_block-box,
  .sigma_icon-block {
    padding: 20px;
    position: relative;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 0;
    z-index: 1;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
    transition: 0.3s;
  }

  .sigma_icon-block i {
    font-size: 40px;
    line-height: 1;
    margin: 0 0 15px;
    color: #777;
    display: inline-block;
  }

  .sigma_icon-block.has-link:hover {
    background-color: #f7f7f7;
  }

  .sigma_icon-block a {
    display: block;
  }

  .sigma_icon-block a h5 {
    font-size: 14px;
  }

  .sigma_icon-block h5 {
    font-size: 22px;
    margin-bottom: 0;
  }

  .sigma_icon-block h5 + p {
    margin-top: 10px;
  }

  .sigma_icon-block p {
    margin-bottom: 0;
  }

  .sigma_icon-block svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  /* Style 2 & 3 */

  .sigma_icon-block.icon-block-2,
  .sigma_icon-block.icon-block-3 {
    display: flex;
    align-items: center;
    padding: 15px;
    box-shadow: none;
    padding: 0;
  }
  .sigma_icon-block.icon-block-2 {
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
  }

  .sigma_icon-block.icon-block-2 i,
  .sigma_icon-block.icon-block-3 i {
    margin: 0;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7e4555;
    margin-right: 20px;
  }
  .sigma_icon-block.icon-block-2 i {
    font-size: 70px;
  }
  .sigma_icon-block.icon-block-3 {
    .icon-wrapper {
      align-self: flex-start;
    }
    img {
      margin-right: 20px;
      max-width: 100px;
    }
  }
  .puja-container {
    .content-container {
      h6 {
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-weight: 300;
      }
    }
  }
  .sigma_icon-block.icon-block-2 .sigma_icon-block-content i {
    font-size: 14px;
    margin: 0;
    display: inline-block;
    margin-top: 20px;
    color: #777;
    opacity: 0.3;
  }
  .col-lg-12 {
    width: 100%;
  }
}
