.sigma_preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #7e4555;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: 0.3s;
  & .hidden {
    opacity: 0;
    visibility: hidden;
  }

  & img {
    animation: fadeInOut 3s linear infinite;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
}
