.drawer-container {
}
.mobile-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .img-container {
    padding: 2rem;
    img {
      width: 130px;
    }
  }
  .menu-container {
    width: 100%;
    a {
      text-decoration: none;
    }
    .button-item {
    }
    button {
      width: 100%;
      color: red;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      //optional
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: 700;
      //optional
      svg {
        height: 2rem;
        width: 2rem;
        font-weight: 700;
      }
      span {
        font-size: 12px;
        font-weight: 700;
        display: flex;
        color: #db4242;
        font-weight: 700;
        svg {
          height: 2rem;
          width: 2rem;
        }
      }
      &:hover {
        span {
          color: #7e4555;
        }
      }
    }
  }
}
