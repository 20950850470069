.about-page-container {
  margin: 4rem;
  display: flex;
  justify-content: center;

  .z-index-3 {
    z-index: 3;
  }

  .img-group {
    position: relative;
    text-align: center;
    padding: 80px;
    .outer-images {
      max-width: 175px;
      height: auto;
    }
  }
  .img-group-inner {
    position: relative;
  }
  .img-group-inner span {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: #7e4555;
  }
  .img-group-inner span + span {
    top: auto;
    right: auto;
    left: -10px;
    bottom: -10px;
    background-color: #fff;
  }
  .img-group img:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .img-group img:nth-child(3) {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .img-group-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .img-group-2 img + img {
    margin-left: auto;
    margin-top: -50%;
  }

  .border {
    border: 2px solid #efefef !important;
  }
  .vertical-seperator {
    display: block;
    width: 3px;
    height: 80px;
    text-align: center;
    background-color: #7e4555;
    margin: 0 auto;
  }

  .sigma_img-box {
    display: flex;
    flex-wrap: wrap;
  }

  .sigma_img-box img + img {
    margin-top: 40px;
    margin-left: 30px;
  }

  .section-before {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    z-index: -1;
    background-color: #db4242;
  }
  .section-before::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .sigma_dots.dots-2 {
    width: 100px;
    left: auto;
    height: 200px;
    right: 0;
    top: 100%;
  }
  .sigma_dots.primary {
    background-image: -o-radial-gradient(#f74f22 1px, transparent 1px),
      -o-radial-gradient(#f74f22 1px, transparent 1px);
    background-image: radial-gradient(#f74f22 1px, transparent 1px),
      radial-gradient(#f74f22 1px, transparent 1px);
  }
  .sigma_dots.secondary {
    background-image: -o-radial-gradient(#022147 1px, transparent 1px),
      -o-radial-gradient(#022147 1px, transparent 1px);
    background-image: radial-gradient(#022147 1px, transparent 1px),
      radial-gradient(#022147 1px, transparent 1px);
  }

  .bottom-skew {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .texture-4 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .texture-3 {
    position: absolute;
    top: -20px;
    left: 0;
  }
  .texture-5 {
    position: absolute;
    top: -20px;
    right: 30%;
    z-index: -1;
    width: 500px;
  }
  .sigma_banner .texture-5 {
    top: -200px;
  }

  .circles .circle {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    display: block;
  }

  .circles .circle.circle-lg {
    width: 290px;
    height: 290px;
  }
  .circles .circle.circle-md {
    width: 160px;
    height: 160px;
  }
  .circles .circle.circle-sm {
    width: 100px;
    height: 100px;
  }

  .sigma_shapes.irregular .irregular-1 svg {
    width: 1020px;
    position: absolute;
    top: -20px;
    right: 0;
  }

  .sigma_shapes.irregular .irregular-2 {
    position: absolute;
    top: -320px;
    left: -430px;
    width: 500px;
    height: 500px;
    transform: rotate(-15deg);
    border-radius: 80px;
  }

  .sigma_shapes.irregular .irregular-3 svg {
    position: absolute;
    bottom: 0px;
    right: -610px;
    width: 920px;
  }

  .sigma_shapes.irregular .irregular-4 svg {
    position: absolute;
    right: -270px;
    top: -40px;
    width: 980px;
    z-index: -1;
  }

  .sigma_shapes.irregular .irregular-5 {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    bottom: -150px;
    left: -150px;
    border: 40px solid #7e4555;
    z-index: -1;
  }

  .sigma_shapes.irregular .irregular-6 svg {
    position: absolute;
    right: 0;
    bottom: -140px;
    width: 1060px;
    z-index: -1;
  }

  .custom-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }
  .custom-img-1 {
    position: absolute;
    bottom: -210px;
    right: 0;
    z-index: -3;
  }

  .spacer {
    height: 140px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
  }
  .spacer.spacer-lg {
    height: 295px;
  }
  .spacer.spacer-xl {
    height: 500px;
  }
  .spacer.spacer-bottom {
    top: auto;
    bottom: 0;
  }

  .spacer.spacer-vertical {
    height: 100%;
    width: 140px;
  }

  .spacer.spacer-vertical.spacer-right {
    right: 0;
    left: auto;
  }
  .spacer.spacer-vertical.spacer-left {
    right: auto;
    left: 0;
  }

  .spacer.spacer-vertical.spacer-xl {
    width: 500px;
  }

  .sigma_canvas canvas,
  .sigma_canvas {
    width: 100%;
  }
  .sigma_map {
    height: 600px;
    width: 100%;
  }
  .sigma_map .sigma_contact-info {
    left: auto;
    right: 0;
  }
  .sigma_map iframe {
    width: 100%;
    height: 100%;
  }
  .sigma_seperator-vertical {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .sigma_seperator-vertical span {
    width: 1px;
    height: 200px;
    background-color: #efefef;
    display: block;
  }
  .sigma_seperator-vertical span:first-child,
  .sigma_seperator-vertical span:last-child {
    margin: 0 20px;
  }
  .sigma_seperator-vertical span:nth-child(2) {
    height: 400px;
  }

  .stroke-text {
    color: #fff;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    font-weight: 400;
  }

  .stroke-text.stroke-text-dark {
    -webkit-text-stroke-color: #db4242;
  }

  .stroke-text span {
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-width: 0;
  }
  .stroke-text span.custom-primary {
    -webkit-text-fill-color: #7e4555;
    -webkit-text-stroke-width: 0;
  }
  .stroke-text span.text-dark {
    -webkit-text-fill-color: #db4242;
    -webkit-text-stroke-width: 0;
  }

  .sigma_preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #7e4555;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: 0.3s;
  }

  .sigma_preloader.hidden {
    opacity: 0;
    visibility: hidden;
  }

  .sigma_preloader img {
    animation: fadeInOut 3s linear infinite;
  }

  .sigma_preloader ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    -webkit-animation: rot 16s linear infinite;
    animation: rot 16s linear infinite;
  }
  .sigma_preloader li {
    width: 40px;
    height: 40px;
    background: #7e4555;
    border-radius: 4px;
    box-shadow: 0 0 1px #fff, 0 0 5px #7e4555, 0 0 10px #7e4555,
      0 0 15px #7e4555, 0 0 25px #7e4555, 0 0 55px #7e4555;
    -webkit-animation: preloaderScale 0.8s linear alternate infinite;
    animation: preloaderScale 0.8s linear alternate infinite;
  }

  .sigma_preloader li:nth-child(1) {
    z-index: 24;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  .sigma_preloader li:nth-child(2) {
    z-index: 23;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .sigma_preloader li:nth-child(3) {
    z-index: 22;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sigma_preloader li:nth-child(4) {
    z-index: 21;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .sigma_preloader li:nth-child(5) {
    z-index: 20;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .sigma_preloader li:nth-child(6) {
    z-index: 19;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .sigma_preloader li:nth-child(7) {
    z-index: 18;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sigma_preloader li:nth-child(8) {
    z-index: 17;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .sigma_preloader li:nth-child(9) {
    z-index: 16;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .sigma_preloader li:nth-child(10) {
    z-index: 15;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .sigma_preloader li:nth-child(11) {
    z-index: 14;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sigma_preloader li:nth-child(12) {
    z-index: 13;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .sigma_preloader li:nth-child(13) {
    z-index: 12;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .sigma_preloader li:nth-child(14) {
    z-index: 11;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .sigma_preloader li:nth-child(15) {
    z-index: 10;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  .sigma_preloader li:nth-child(16) {
    z-index: 9;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .sigma_preloader li:nth-child(17) {
    z-index: 8;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .sigma_preloader li:nth-child(18) {
    z-index: 7;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .sigma_preloader li:nth-child(19) {
    z-index: 6;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  .sigma_preloader li:nth-child(20) {
    z-index: 5;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }
  .sigma_preloader li:nth-child(21) {
    z-index: 4;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .sigma_preloader li:nth-child(22) {
    z-index: 3;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .sigma_preloader li:nth-child(23) {
    z-index: 2;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  .sigma_preloader li:nth-child(24) {
    z-index: 1;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }
  .sigma_preloader li:nth-child(25) {
    z-index: 0;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }

  .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;

    -webkit-animation: sk-rotate 2s infinite linear;
    animation: sk-rotate 2s infinite linear;
  }

  .dot1,
  .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #7e4555;
    border-radius: 100%;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  a,
  abbr,
  acronym,
  address,
  applet,
  article,
  aside,
  audio,
  b,
  big,
  blockquote,
  body,
  canvas,
  caption,
  center,
  cite,
  code,
  dd,
  del,
  details,
  dfn,
  div,
  dl,
  dt,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  hgroup,
  html,
  i,
  iframe,
  img,
  ins,
  kbd,
  label,
  legend,
  li,
  mark,
  menu,
  nav,
  object,
  ol,
  output,
  p,
  pre,
  q,
  ruby,
  s,
  samp,
  section,
  small,
  span,
  strike,
  strong,
  sub,
  summary,
  sup,
  table,
  tbody,
  td,
  tfoot,
  th,
  thead,
  time,
  tr,
  tt,
  u,
  ul,
  var,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
  }

  .opacity-0 {
    opacity: 0;
  }

  .opacity-25 {
    opacity: 0.25;
  }

  .opacity-50 {
    opacity: 0.5;
  }

  .opacity-75 {
    opacity: 0.75;
  }

  .opacity-100 {
    opacity: 1;
  }

  .pattern-building,
  .pattern-squares,
  .pattern-map,
  .primary-overlay,
  .light-overlay,
  .secondary-overlay,
  .dark-overlay {
    position: relative;
    z-index: 1;
  }

  .secondary-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(2 33 71 / 62%);
    z-index: -1;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  .dark-overlay.primary-overlay::before {
    background-color: rgba(72, 172, 110, 0.8);
  }

  .dark-overlay.dark-overlay-2::before {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .dark-overlay.dark-overlay-3::before {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .sigma_sticky-section {
    position: sticky;
    top: 30px;
  }

  html {
    overflow-x: hidden;
  }

  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.9;
    font-weight: 400;
    color: #767e88;
    overflow-x: hidden;
  }

  hr {
    margin: 30px 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .imgs-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .imgs-wrapper img {
    position: absolute;
    max-width: 300px;
  }

  .imgs-wrapper img:nth-child(1) {
    top: 150px;
    max-width: 400px;
    left: -200px;
  }

  .imgs-wrapper img:nth-child(2) {
    right: -100px;
    bottom: 0;
  }

  .sigma_single-img-wrapper {
    position: relative;
  }

  .sigma_single-img-wrapper img {
    border-radius: 8px;
  }

  .signature {
    width: 220px;
    margin-bottom: 20px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .close-btn {
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    outline: none;
  }

  .close-btn:focus {
    outline: none;
  }

  .close-btn span {
    position: absolute;
    width: 2px;
    height: 15px;
    display: block;
    background-color: #fff;
    opacity: 0.6;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .close-btn.close-dark span {
    background-color: #db4242;
  }

  .close-btn.close-danger span {
    background-color: red;
  }

  .close-btn span:nth-child(2) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .close-btn:hover {
    transform: rotate(45deg);
  }

  .close-btn.dark span {
    background-color: #db4242;
  }

  .icon-2x {
    font-size: 2rem;
  }

  .icon-3x {
    font-size: 3rem;
  }

  .icon-4x {
    font-size: 4rem;
  }

  .border-0 {
    border: 0;
  }

  .bg-cover {
    background-size: cover;
  }

  .bg-lg {
    background-size: 600px;
  }

  .bg-parallax {
    background-attachment: fixed;
  }

  .bg-contain {
    background-size: contain;
  }

  .bg-norepeat {
    background-repeat: no-repeat;
  }

  .bg-center {
    background-position: center;
  }

  .bg-left {
    background-position: left;
  }

  .bg-right {
    background-position: right;
  }

  .bg-top {
    background-position: top;
  }

  .bg-bottom {
    background-position: bottom;
  }
  .bg-bottom-left {
    background-position: bottom left;
  }
  .bg-bottom-right {
    background-position: bottom right;
  }

  .container-fluid {
    padding-left: 150px;
    padding-right: 150px;
  }

  /*======================
1.1. Typography
========================*/

  a {
    color: #7e4555;
    text-decoration: none;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .sigma_list {
    margin: 40px 0;
  }
  .sigma_list.list-2 {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  .sigma_list li {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    color: #db4242;
    font-weight: 600;
  }

  .sigma_list li + li {
    margin-top: 20px;
  }

  .sigma_list li::before {
    font-family: "Font Awesome 5 Pro";
    content: " \2714";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-rendering: auto;
    line-height: 1;
    font-size: 20px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-right: 10px;
    color: #7e4555;
    background-color: #e6e8e7;
  }

  .sigma_list.sigma_list-2 li {
    font-weight: 400;
    font-size: 14px;
  }

  .sigma_list.sigma_list-2 li::before {
    width: auto;
    height: auto;
    background-color: transparent;
  }

  .small,
  small {
    line-height: 1.7;
  }

  .blockquote,
  blockquote {
    position: relative;
    margin: 20px 0;
    font-size: 14px;
    color: #db4242;
    padding: 10px 30px;
    background-position: center;
    background-size: cover;
    background-color: #f7f7f7;
    border-left: 2px solid #7e4555;
  }

  p.blockquote:before,
  p.blockquote:after {
    content: "";
    position: absolute;
    left: -9px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 30px;
    background-color: #7e4555;
  }

  p.blockquote:after {
    left: 6px;
  }

  p.blockquote.light-border:before,
  p.blockquote.light-border:after {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .blockquote.light {
    background-color: transparent;
    color: #fff;
  }
  .blockquote.bg-transparent {
    color: #020202;
  }
  .blockquote.light-border,
  blockquote.light-border {
    border-left-color: rgba(255, 255, 255, 0.4);
  }

  blockquote {
    border-left: 0;
    text-align: center;
    padding: 50px;
    border-radius: 10px;
  }

  .entry-content blockquote p,
  blockquote p {
    position: relative;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 700;
    color: #db4242;
  }

  blockquote cite {
    position: relative;
    font-weight: 600;
    text-transform: uppercase;
    color: #7e4555;
    display: block;
    margin: 0 0 10px;
  }

  blockquote::before {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 240px;
    content: "\f10e";
    color: #fff;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }
  .more-info {
    color: #020202;
    margin: 20px 0;
    font-size: 14px;
    // color: #db4242;
    padding: 10px 30px;
    background-position: center;
    background-size: cover;
    background-color: #f7f7f7;
    text-align: start;
    transition: 0.75s ease-in-out;
  }

  /*======================
1.2 Section Spacings
========================*/
  .section {
    position: relative;
    padding: 110px 0;
  }
  .section .container {
    position: relative;
  }

  .section.section-padding {
    padding: 110px 0 80px;
  }
  .section.section-lg {
    padding: 110px 0 200px;
  }
  .section.section-md {
    padding: 110px 0 160px;
  }

  .sigma_section-fw {
    padding: 0 80px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }

  .me-lg-30 {
    margin-left: 30px;
  }

  .section.section-padding.category_section {
    margin-top: -50px;
    padding-top: 0;
  }
  .section-title .badge {
    margin-bottom: 10px;
  }

  .section-title .subtitle {
    position: relative;
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: #7e4555;
    font-weight: 700;
    img {
      width: 3rem;
      height: 3rem;
    }
  }
  .section-title {
    .title {
      position: relative;
      margin: 0;
      font-size: 30px;
      line-height: 1.2;
      margin-bottom: 15px;
      font-weight: 500;
      color: #db4242;
    }
  }

  /*==================
1.4. Buttons
====================*/

  .sigma_btn-custom {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    background-color: #7e4555;
    border: 0;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 15px 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border-radius: 30px;
    font-weight: 600;
    overflow: hidden;
    z-index: 1;
  }

  .sigma_btn-custom::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    background-color: #d43308;
    transform: scaleY(0);
    transform-origin: 50%;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  .sigma_btn-custom:focus::before,
  .sigma_btn-custom:hover::before {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }

  .sigma_btn-custom:hover,
  .sigma_btn-custom:focus {
    color: #fff;
    outline: none;
  }
  .sigma_btn-custom.primary {
    background-color: #7e4555;
  }

  .sigma_btn-custom.transparent {
    background-color: transparent;
    border: 1px solid transparent;
    color: #db4242;
  }
  .sigma_btn-custom.transparent::before {
    background-color: #ec6a47;
  }
  .sigma_btn-custom.transparent:hover,
  .sigma_btn-custom.transparent:focus {
    color: #fff;
    border: 1px solid #efefef;
  }

  .sigma_btn-custom.primary::before {
    background-color: #ec6a47;
  }

  .sigma_btn-custom.dark {
    background-color: #db4242;
    color: #fff;
    box-shadow: none;
  }
  .sigma_btn-custom.dark::before {
    background-color: #65303f;
  }
  .sigma_btn-custom.light:hover,
  .sigma_btn-custom.light:focus,
  .sigma_btn-custom.dark:hover,
  .sigma_btn-custom.dark:focus {
    color: #fff;
  }
  .sigma_btn-custom.light {
    background-color: transparent;
    color: #db4242;
    box-shadow: none;
    border: 2px solid #efefef;
  }

  .sigma_btn-custom.light::before {
    background-color: #282828;
  }
  .sigma_btn-custom.white {
    background-color: #fff;
    color: #db4242;
    box-shadow: none;
  }
  .sigma_btn-custom.white::before {
    background-color: #efefef;
  }

  .sigma_btn-custom.secondary {
    background-color: #db4242;
    color: #fff;
  }

  .sigma_btn-custom.secondary::before {
    background-color: #65303f;
  }

  .sigma_btn-custom.btn-sm {
    padding: 12px 26px;
    font-size: 14px;
  }
  .sigma_btn-custom.btn-pill {
    border-radius: 55px;
  }

  .sigma_btn-custom i {
    margin-left: 10px;
  }

  .btn-group .sigma_btn-custom + .sigma_btn-custom {
    padding: 12px 14px;
  }

  .btn-group .sigma_btn-custom.btn-sm + .sigma_btn-custom.btn-sm {
    padding: 12px 16px;
  }

  .input-group-prepend,
  .input-group-append {
    margin: 0;
    display: flex;
  }

  .input-group-prepend .sigma_btn-custom,
  .input-group-prepend .sigma_btn-custom::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .middle-img {
    max-width: 75%;
    height: auto;
  }
}
