.template-component {
  .nav {
    z-index: 999;
    position: absolute;
    width: 100% !important;
  }
  .content-element {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .footer-component {
    position: fixed;
    bottom: 0rem;
  }
}
