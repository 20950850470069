.slider-container {
  width: 100%;
  // .carousel-inner {
  //   padding: 140px 0 140px;
  // }

  .slider-css {
    border-radius: 50%;
    background-color: #db4242;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    height: 5px;
    width: 5px;
    height: fit-content;
    top: 50%;
  }
  .carousel-control-prev {
    height: fit-content;
    width: fit-content;
    margin-left: 2rem;
    top: 50%;
    & span {
      @extend .slider-css;
    }
  }
  .carousel-control-next {
    height: fit-content;
    width: fit-content;
    margin-right: 2rem;
    top: 50%;
    & span {
      @extend .slider-css;
    }
  }
}

@media screen and (max-width: 821px) {
  .slider-container {
    width: 100%;
    // .carousel-inner {
    //   padding: 140px 0 140px;
    // }

    .slider-css {
      border-radius: 50%;
      background-color: #db4242;
      border: none;
      color: white;
      padding: 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      height: 5px;
      width: 5px;
      height: fit-content;
      top: 50%;
    }
    .carousel-control-prev {
      height: fit-content;
      width: fit-content;
      margin-left: 2rem;
      top: 50%;
      & span {
        @extend .slider-css;
      }
    }
    .carousel-control-next {
      height: fit-content;
      width: fit-content;
      margin-right: 2rem;
      top: 50%;
      & span {
        @extend .slider-css;
      }
    }
  }
}
