.broadcast-container {
  background-color: antiquewhite;
  .video-container {
    @keyframes pulseInOut {
      0% {
        opacity: 1;
        transform: scale(0.3);
      }
      100% {
        opacity: 0;
        transform: scale(1.3);
      }
    }
    .section-title p {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 3rem;
        height: 3rem;
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .section-title {
      .title {
        position: relative;
        margin: 0;
        font-size: 46px;
        line-height: 1.2;
        margin-bottom: 15px px;
        font-weight: 700;
        color: #db4242;
      }
    }
    .section-title.section-title-2 .subtitle {
      color: #7e4555;
      background-color: #e6e8e7;
      display: inline-block;
      padding: 5px 20px;
      font-size: 13px;
    }
    .sigma_video-popup-wrap {
      position: relative;
      transition: 0.3s;
      svg {
        font-weight: 900;
        width: 3rem;
        height: 3rem;
      }
    }
    .sigma_video-popup-wrap .sigma_video-popup {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
    .sigma_video-popup {
      position: relative;
      width: 80px;
      height: 80px;
      background-color: #7e4555;
      color: #fff;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .sigma_video-popup::before {
      content: "";
      position: absolute;
      top: -10px;
      left: -10px;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      border: 2px solid #fff;
      border-radius: 50%;
      animation-name: pulseInOut;
      opacity: 0;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    .sigma_video-popup::after {
      content: "";
      position: absolute;
      top: -10px;
      left: -10px;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      border: 2px solid #fff;
      border-radius: 50%;
      animation-name: pulseInOutSM;
      opacity: 0;
      animation-duration: 3.5s;
      animation-iteration-count: infinite;
    }
    .sigma_video-popup.bg-white {
      color: #7e4555;
    }
    .sigma_video-popup.bg-white:hover {
      background-color: #7e4555 !important;
      color: #fff;
    }

    .sigma_video-popup.popup-lg {
      width: 140px;
      height: 140px;
      font-size: 20px;
    }
    .sigma_video-popup.popup-sm {
      width: 50px;
      height: 50px;
    }
    .sigma_video-popup.popup-dark {
      background-color: #2d2d2d;
      color: #fff;
    }

    .sigma_video-popup.secondary {
      color: #db4242;
    }
    .sigma_video-popup.secondary::after {
      background-color: #db4242;
    }

    .sigma_video-popup:hover {
      color: #fff;
    }

    .sigma_broadcast-video .sigma_video-popup-wrap:hover {
      box-shadow: 0px 10px 20px 0px rgb(53 82 99 / 52%);
    }

    .sigma_broadcast-video .sigma_video-popup-wrap img {
      width: 100%;
    }
    .section {
      position: relative;
      padding: 110px 0;
    }
    .section .container {
      position: relative;
    }

    .section.section-padding {
      padding: 110px 0 80px;
    }
    .section.section-lg {
      padding: 110px 0 200px;
    }
    .section.section-md {
      padding: 110px 0 160px;
    }

    .sigma_section-fw {
      padding: 0 80px;
    }

    .mb-30 {
      margin-bottom: 30px;
    }
    .mb-50 {
      margin-bottom: 50px;
    }

    .me-lg-30 {
      margin-left: 30px;
    }

    .section.section-padding.category_section {
      margin-top: -50px;
      padding-top: 0;
    }
    .section-title .badge {
      margin-bottom: 10px;
    }

    .section-title .subtitle {
      position: relative;
      margin-bottom: 15px;
      font-size: 16px;
      text-transform: uppercase;
      color: #7e4555;
      font-weight: 700;
    }
    .section-title.text-start .subtitle {
      justify-content: flex-start;
    }

    .section-title.section-title-2 .subtitle.light {
      background-color: rgba(255, 255, 255, 0.4);
    }
    .section-title.section-title-2 .subtitle::before,
    .section-title.section-title-2 .subtitle::after {
      display: none;
    }
    .small-text {
      letter-spacing: 3px;
      text-transform: uppercase;
      font-size: 16px;
    }

    .section-title .title {
      position: relative;
      margin: 0;
      font-size: 46px;
      line-height: 1.2;
      margin-bottom: 15px;
      font-weight: 700;
    }

    .section-title {
      position: relative;
      margin-bottom: 50px;
      max-width: 500px;
    }
    .section-title.text-center {
      margin: 0 auto 50px;
    }

    .section-title.flex-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 100%;
    }

    .section-title.flex-title .nav {
      margin-left: auto;
    }

    .section-title.flex-title .nav-item {
      margin: 0;
    }

    .section-title.flex-title .nav-item + .nav-item {
      margin-left: 10px;
    }

    .section-title.flex-title .nav-item .nav-link {
      margin: 0;
    }

    .section-button {
      margin-top: 30px;
    }
    .sigma_broadcast-video .sigma_video-popup-wrap:hover {
      box-shadow: 0px 10px 20px 0px rgb(53 82 99 / 52%);
    }

    .sigma_broadcast-video .sigma_video-popup-wrap img {
      width: 100%;
    }

    .sigma_video-popup-wrap {
      position: relative;
      transition: 0.3s;
    }
    .sigma_video-popup-wrap .sigma_video-popup {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
    .sigma_video-popup {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .video-group {
      img {
        width: 30.6rem;
        height: 19.2rem;
      }
      h6 {
        font-family: "Poppins", sans-serif;
        margin: 0 0 20px;
        line-height: 1.2;
        color: #db4242;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.7;
      }
    }
    .sigma_box {
      padding: 30px;
      margin: 50px 0;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
      .custom-primary {
        color: #7e4555;

        font-weight: 600;

        font-size: 16px;
      }
      h4 {
        font-size: 28px;
        line-height: 1.4;
        font-family: "Poppins", sans-serif;
        margin: 0 0 20px;
        line-height: 1.2;
        color: #db4242;
        font-weight: 700;
      }
      .m-0 {
        font-size: 14px;
        line-height: 1.9;
        margin-bottom: 15px;
        color: #777;
      }
    }
    .sigma_box-lg {
      padding: 40px;
    }
    .sigma_box.box-lg {
      padding: 40px;
    }
    .sigma_box-absolute {
      position: absolute;
      top: -13px;
      left: -50px;
      width: calc(100% + 100px);
      z-index: 1;
    }

    .sigma_box-progress {
      position: relative;
    }
    .sigma_box-progress i {
      font-size: 110px;
      opacity: 0.2;
      position: absolute;
      line-height: 1;
      top: 20px;
      right: 20px;
    }
  }
}
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content {
    width: 80rem;
    height: 50rem;
    margin: 5rem 20rem;
    padding: 0;

    iframe {
      width: 80rem;
      height: 50rem;
    }
    .modal-body {
      padding: 0;
    }
  }
}
@media screen and (max-width: 821px) {
  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-content {
      width: 50rem;
      height: 40rem;
      margin: 5rem 20rem;
      padding: 0;

      iframe {
        width: 50rem;
        height: 40rem;
      }
      .modal-body {
        padding: 0;
      }
    }
  }
}
