.contat-details-container {
  padding: 3rem;
  .section {
    position: relative;
  }
  .section .container {
    position: relative;
  }

  .section.section-lg {
    padding: 110px 0 200px;
  }
  .section.section-md {
    padding: 110px 0 160px;
  }

  .sigma_section-fw {
    padding: 0 80px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }

  .me-lg-30 {
    margin-left: 30px;
  }

  .section.section-padding.category_section {
    margin-top: -50px;
    padding-top: 0;
  }
  .section-title .badge {
    margin-bottom: 10px;
  }

  .section-title .subtitle {
    position: relative;
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: #7e4555;
    font-weight: 700;
  }
  .section-title.text-start .subtitle {
    justify-content: flex-start;
  }

  .section-title.text-md-right .subtitle,
  .section-title.text-end .subtitle {
    justify-content: flex-end;
  }

  .section-title.text-md-right,
  .section-title.text-end {
    margin: 0 0 50px auto;
  }

  .section-title.text-md-right .subtitle::after,
  .section-title.text-end .subtitle::after,
  .section-title.text-start .subtitle::after {
    display: none;
  }

  .section-title .subtitle::after,
  .section-title .subtitle::before {
    content: "\f679";
    font-family: "Font Awesome 5 Pro";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-size: 18px;
    margin-right: 15px;
  }

  .section-title .subtitle::after {
    margin-right: 0;
    margin-left: 15px;
  }

  .section-title p {
    font-size: 16px;
  }

  .section-title.section-title-2 {
    position: relative;
  }
  .section-title.section-title-2 .subtitle {
    color: #7e4555;
    background-color: #e6e8e7;
    display: inline-block;
    padding: 5px 20px;
    font-size: 13px;
  }
  .section-title.section-title-2 .subtitle.light {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .section-title.section-title-2 .subtitle::before,
  .section-title.section-title-2 .subtitle::after {
    display: none;
  }
  .small-text {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 16px;
  }

  .section-title .title {
    position: relative;
    margin: 0;
    font-size: 46px;
    line-height: 1.2;
    margin-bottom: 15px;
    font-weight: 700;
  }

  .section-title {
    position: relative;
    margin-bottom: 50px;
    max-width: 500px;
  }
  .section-title.text-center {
    margin: 0 auto 50px;
  }

  .section-title.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .section-title.flex-title .nav {
    margin-left: auto;
  }

  .section-title.flex-title .nav-item {
    margin: 0;
  }

  .section-title.flex-title .nav-item + .nav-item {
    margin-left: 10px;
  }

  .section-title.flex-title .nav-item .nav-link {
    margin: 0;
  }

  .section-button {
    margin-top: 30px;
  }

  .ov-hidden {
    overflow: hidden;
  }

  /* Style 6 and 7 */
  .sigma_icon-block.icon-block-6,
  .sigma_icon-block.icon-block-7 {
    text-align: center;
    background-color: transparent;
    box-shadow: none;
  }
  .sigma_icon-block.icon-block-6 i,
  .sigma_icon-block.icon-block-7 i {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 auto 20px;
    font-size: 50px;
    border: 3px solid #7e4555;
    color: #7e4555;
  }
  .sigma_icon-block.icon-block-6 h5,
  .sigma_icon-block.icon-block-7 h5 {
    font-size: 24px;
  }

  .sigma_icon-block.icon-block-6 .count,
  .sigma_icon-block.icon-block-7 .count {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 120px;
    opacity: 0.2;
    line-height: 1;
    z-index: -1;
  }

  .sigma_icon-block.icon-block-7 {
    box-shadow: none;
    border: 0;
    text-align: left;
    padding: 40px;
    background-color: #282828;
    border-radius: 10px;
    max-width: 300px;
  }

  .sigma_icon-block.icon-block-7 i {
    width: auto;
    height: auto;
    font-size: 60px;
    display: inline-flex;
    justify-content: flex-start;
    background-color: transparent;
    border: 0;
  }
  .col-lg-4 {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  .sigma_icon-block.icon-block-7 > i {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 140px;
    opacity: 0.1;
    color: #fff;
  }
  .sigma_icon-block.icon-block-7 p,
  .sigma_icon-block.icon-block-7 h5 {
    color: #fff;
  }

  .sigma_icon-block.icon-block-7 .sigma_search-adv-input {
    margin-top: 20px;
  }
  .sigma_icon-block.icon-block-7 .form-control {
    background-color: #db4242;
  }
  .sigma_icon-block.icon-block-7 button i {
    font-size: 14px;
    color: #fff;
    margin: 0;
  }

  .sigma_icon-block.icon-block-7.text-center {
    max-width: 100%;
  }

  .sigma_icon-block.icon-block-7.text-center i {
    margin: 0;
    margin-top: 20px;
  }

  .sigma_icon-block.icon-block-7.text-center > i {
    margin: 0;
    top: 50%;
    left: 50%;
    right: auto;
    color: #db4242;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -50%);
  }
  .sigma_icon-block.icon-block-7.text-center:hover {
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
    border-color: transparent;
  }
  .sigma_icon-block.icon-block-7.text-center:hover > i {
    opacity: 0.1;
    visibility: visible;
  }
  .sigma_icon-block.icon-block-7.text-center .sigma_icon-block-content > span {
    color: #7e4555;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    text-transform: uppercase;
  }
  .sigma_icon-block.icon-block-7.text-center span i {
    font-size: 14px;
    margin-left: 5px;
  }

  .sigma_icon-block.icon-block-7.light {
    background-color: #fff;
    border: 2px solid #efefef;
  }

  .sigma_icon-block.icon-block-7.light h5 {
    color: #db4242;
  }
  .sigma_icon-block.icon-block-7.light p {
    color: #767e88;
    font-size: initial;
  }

  /* Style 8 */
  .sigma_icon-block.icon-block-8 {
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
  }
  .sigma_icon-block.icon-block-8 .icon-wrapper {
    margin-right: 10px;
  }
  .sigma_icon-block.icon-block-8 h6 {
    color: #fff;
    margin: 0;
    font-size: 14px;
  }
  .sigma_icon-block.icon-block-8 .icon-wrapper i {
    opacity: 0.3;
  }
  .sigma_icon-block.icon-block-8 .icon-wrapper i,
  .sigma_icon-block.icon-block-8 .sigma_rating,
  .sigma_icon-block.icon-block-8 .sigma_rating i {
    margin: 0;
  }
  .sigma_icon-block.icon-block-8 span {
    font-weight: 600;
  }
  .sigma_icon-block.icon-block-7.text-center {
    max-width: 100%;
  }

  .sigma_icon-block.icon-block-7.text-center i {
    margin: 0;
    margin-top: 20px;
  }

  .sigma_icon-block.icon-block-7.text-center > i {
    margin: 0;
    top: 50%;
    left: 50%;
    right: auto;
    color: #db4242;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -50%);
  }
  .sigma_icon-block.icon-block-7.text-center:hover {
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
    border-color: transparent;
  }

  .sigma_icon-block.icon-block-7.text-center .sigma_icon-block-content > span {
    color: #7e4555;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    text-transform: uppercase;
    font-size: initial;
  }
  .sigma_icon-block.icon-block-7.text-center span i {
    font-size: 14px;
    margin-left: 5px;
  }
  //   .flaticon-email:before {
  //     content: "\f105";
  //   }
}
