.info-card-container {
  .section {
    position: relative;
    padding: 110px 0;
  }
  .section .container {
    position: relative;
  }

  .section.section-padding {
    padding: 110px 0 80px;
  }
  .section.section-lg {
    padding: 110px 0 200px;
  }
  .section.section-md {
    padding: 110px 0 160px;
  }

  .sigma_section-fw {
    padding: 0 80px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }

  .me-lg-30 {
    margin-left: 30px;
  }

  .section.section-padding.category_section {
    margin-top: -50px;
    padding-top: 0;
  }
  .section-title .badge {
    margin-bottom: 10px;
  }

  .section-title .subtitle {
    position: relative;
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: #7e4555;
    font-weight: 700;
  }
  .section-title.text-start .subtitle {
    justify-content: flex-start;
  }

  .section-title.text-md-right .subtitle,
  .section-title.text-end .subtitle {
    justify-content: flex-end;
  }

  .section-title.text-md-right,
  .section-title.text-end {
    margin: 0 0 50px auto;
  }

  .section-title.text-md-right .subtitle::after,
  .section-title.text-end .subtitle::after,
  .section-title.text-start .subtitle::after {
    display: none;
  }

  .section-title .subtitle::after,
  .section-title .subtitle::before {
    content: "\f679";
    font-family: "Font Awesome 5 Pro";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-size: 18px;
    margin-right: 15px;
  }

  .section-title .subtitle::after {
    margin-right: 0;
    margin-left: 15px;
  }

  .section-title p {
    font-size: 16px;
  }

  .section-title.section-title-2 {
    position: relative;
  }
  .section-title.section-title-2 .subtitle {
    color: #7e4555;
    background-color: #e6e8e7;
    display: inline-block;
    padding: 5px 20px;
    font-size: 13px;
  }
  .section-title.section-title-2 .subtitle.light {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .section-title.section-title-2 .subtitle::before,
  .section-title.section-title-2 .subtitle::after {
    display: none;
  }
  .small-text {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 16px;
  }

  .section-title .title {
    position: relative;
    margin: 0;
    font-size: 46px;
    line-height: 1.2;
    margin-bottom: 15px;
    font-weight: 700;
  }

  .section-title {
    position: relative;
    margin-bottom: 50px;
    max-width: 500px;
  }
  .section-title.text-center {
    margin: 0 auto 50px;
  }

  .section-title.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .section-title.flex-title .nav {
    margin-left: auto;
  }

  .section-title.flex-title .nav-item {
    margin: 0;
  }

  .section-title.flex-title .nav-item + .nav-item {
    margin-left: 10px;
  }

  .section-title.flex-title .nav-item .nav-link {
    margin: 0;
  }

  .section-button {
    margin-top: 30px;
  }
  .sigma_header.header-fw .sigma_header-top > .container,
  .sigma_header.header-fw .sigma_header-middle > .container,
  .sigma_header.header-fw .sigma_header-bottom > .container {
    width: 100%;
    max-width: 100%;
    padding-left: 4vw;
    padding-right: 4vw;
  }
  .sigma_footer-top .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .row {
    margin-left: -5px;
    margin-right: -5px;
  } /* Accordion */
  .accordion .card {
    margin-bottom: 0;
  }
  .accordion .card {
    background-color: #f7f7f7;
  }

  .accordion .card .card-header {
    position: relative;
    background-color: #f7f7f7;
    border-bottom: 0;
    padding: 1.2rem 2rem;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #db4242;
    font-weight: 600;
    font-size: initial;
  }

  .accordion .card-header[aria-expanded="true"] {
    background-color: #7e4555;
    color: #fff;
  }

  .accordion .card-header::after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1.25rem;
    width: 10px;
    height: 2px;
    background-color: #848486;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 1;
  }

  .accordion .card-header::before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1.25rem;
    width: 10px;
    height: 2px;
    background-color: #848486;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 1;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .accordion .card-header[aria-expanded="true"]::before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 0;
  }

  .accordion .card-header[aria-expanded="true"]::after,
  .accordion .card-header[aria-expanded="true"]::before {
    background-color: #fff;
  }

  .accordion .card-header i {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 10px;
    font-size: 20px;
  }

  .accordion .card-header[aria-expanded="true"] i {
    color: #7e4555;
  }

  .accordion .card {
    border: #f7f7f7;
    border-radius: 10px;
    overflow: hidden;
  }

  .accordion.with-gap .card-header {
    border-radius: 0;
  }

  .accordion.with-gap .card {
    margin-bottom: 10px;
  }

  .accordion.with-gap .card:last-child {
    margin-bottom: 0;
  }

  .accordion.with-gap .card:first-of-type,
  .accordion.with-gap .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid #efefef;
  }
  .card-body {
    padding: 0 0 18px;
    padding: 1rem 1rem;
    font-size: initial;
    color: grey;
  }
}
