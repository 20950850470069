.nav-bar-container {
  width: 100%;
  // background-color: #f4c430;
  background-color: antiquewhite;
  margin: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  //   border: 2px solid black;
  height: 12rem;
  & .sigma_logo-wrapper {
    a {
      img {
        width: 200px;
        height: 250px;
      }
    }
    .sigma_header-controls {
      display: none;
    }
    height: 12rem;
    border-right: 1px solid #efefef;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    & .navbar-brand {
      padding: 1rem 0;
      width: 1.9rem;
      a {
        padding: 0;
        margin: 0;
      }
    }
  }
  & .nav-middle {
    .logo-pic {
      display: none;
      img {
        width: 130px;
      }
    }
    flex: 1;
    height: 12rem;

    .upper {
      border-bottom: 1px solid #efefef;
      padding: 0 2rem;
      height: 6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .info-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
          color: #000000;
          padding: 20px 15px;
          text-transform: uppercase;
          font-size: 13px;
          position: relative;
          display: block;
          font-weight: 600;
          text-decoration: none;
        }
      }
      .lang-items {
        h4 {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
        }
        img {
          width: 2rem;
          height: 2rem;
          margin-right: 2px;
        }
      }
    }
    .lower {
      position: static;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem;
      height: 6rem;
      .menu-container {
        display: flex;
        a {
          text-decoration: none;
        }
        .btn-link {
          font-weight: 500;
          text-decoration: none;
          size: 2rem;
          font-size: initial;
          color: black;

          display: flex;
          align-items: center;
          justify-content: space-between;
          button {
            outline: none;
          }
          svg {
            margin-right: 0.3rem;
          }
          &:hover {
            color: #7e4555;
          }
          &:focus {
            outline: none;
            border: none;
          }
          &:active {
            outline: none;
          }
        }
      }
    }
  }
  .nav-donation {
    .phone-button {
      display: none;
    }
    border-left: 1px solid #efefef;
    padding: 2rem;
    height: 12rem;
    display: flex;
    align-items: center;
    .donate-button {
      & button {
        cursor: pointer;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        position: relative;
        background-color: #7e4555;
        border: 0;
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        padding: 15px 30px;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        border-radius: 30px;
        font-weight: 600;
        overflow: hidden;
        z-index: 1;
        &:hover::after {
          animation-name: BounceUpDown;
          animation-duration: 1s;
          animation-iteration-count: 1;
          animation-fill-mode: both;
          animation-timing-function: ease-out;
        }
      }
      @keyframes BounceUpDown {
        0% {
          transform: translateY(0);
        }

        25% {
          transform: translateY(-5px);
        }

        50% {
          transform: translateY(0);
        }

        75% {
          transform: translateY(-2px);
        }

        100% {
          transform: translateY(0);
        }
      }
    }
  }
}

@media screen and (max-width: 821px) {
  .nav-bar-container {
    margin: 0;
    & .sigma_logo-wrapper {
      a {
        img {
          width: 130px;
        }
      }
      .sigma_header-controls {
        display: block;

        svg {
          cursor: pointer;
          height: 5rem;
          width: 5rem;
          color: #db4242;
          transition: 0.5s;
          &:hover {
            color: #7e4555;
          }
        }
      }
      a {
        display: none;
        img {
          width: 130px;
        }
      }
      & .navbar-brand {
      }
    }
    & .nav-middle {
      display: flex;
      justify-content: center;
      align-items: center;

      .logo-pic {
        display: block;
      }
      .upper {
        display: none;
      }
      .lower {
        display: none;
      }
    }
    .nav-donation {
      .phone-button {
        display: block;
        width: 5rem;
        height: 5rem;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        svg {
          cursor: pointer;
          height: 5rem;
          width: 5rem;
          color: white;
          transition: 0.5s;
        }
      }
      .donate-button {
        display: none;
        & button {
          &:hover::after {
          }
        }
        @keyframes BounceUpDown {
          0% {
            transform: translateY(0);
          }

          25% {
            transform: translateY(-5px);
          }

          50% {
            transform: translateY(0);
          }

          75% {
            transform: translateY(-2px);
          }

          100% {
            transform: translateY(0);
          }
        }
      }
    }
  }
  .lang-items {
    h4 {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
    }
    img {
      width: 2rem;
      height: 2rem;
      margin-right: 2px;
    }
  }
}
