.contact-us-container {
  background-image: url("https://metropolitanhost.com/themes/themeforest/html/maharatri/assets/img/subheader.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 60px;
    margin-bottom: 0;
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding-top: 5rem;
  }
  .contact-button-container {
    padding: 20px 28px;
    margin: 0;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 10px 20px 0px rgb(53 82 99 / 9%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 22rem;
    max-width: 25rem;
    span {
      color: #7e4555;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      align-items: center;
    }
    img {
      width: 3rem;
    }
    .color-grey {
      color: #777;
    }
  }
}
@media screen and (max-width: 1100px) {
  .contact-us-container {
    .contact-button-container {
      width: 25rem;
    }
  }
}
