.sigma_footer-container {
  border-top: 1px solid #efefef;
  .sigma_footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .sigma_footer-copyright {
      p {
        font-size: 14px;
        line-height: 1.9;
        font-weight: 600;
        color: #777;
        a {
          color: #7e4555;
          text-decoration: none;
        }
      }
    }
    .sigma_footer-logo {
      background-color: transparent;
      img {
        align-self: center;
        width: 300px;
        height: 140px;
        background-color: transparent;
        display: flex;
        box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
        align-items: center;
      }
    }
    .sigma_sm {
      display: flex;
      align-items: center;
      margin: 0;

      a {
        display: inline-block;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #db4242;
        background-color: rgba(128, 128, 128, 0.284);
        margin-left: 1rem;
        box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
        transition: 0.3s;
        &:hover {
          background-color: #7e4555;
          color: #fff;
        }
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}
@media screen and (max-width: 821px) {
  .sigma_footer-container {
    .sigma_footer-bottom {
      padding: 3rem;
      .sigma_footer-logo {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .sigma_footer-container {
    .sigma_footer-bottom {
      flex-direction: column;
      padding: 3rem;
      .sigma_footer-logo {
        display: none;
      }
    }
  }
}
