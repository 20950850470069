.volunteers-container {
  background-color: antiquewhite;
  display: flex;
  flex-direction: column;
  .sigma_volunteers_01 {
    display: block !important;
    .sigma_volunteers {
      .sigma_volunteers-body {
        .top-image {
          img {
            width: 45rem;
            height: 45rem;
          }
        }
      }
    }
  }
  .info-container {
    margin-top: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .sigma_volunteers-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 50%;
        width: 270px;
        height: 280px;
      }
      .sigma_volunteers-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        .volunteers_details {
          margin: 0 10rem;
        }
        .icons_container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          a {
            display: inline-block;

            cursor: pointer;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: 0 1rem;
            margin-top: -1rem;
            color: #2c65c8;
            background-color: rgba(128, 128, 128, 0.284);
            box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
            transition: 0.3s;
            &:hover {
              background-color: #7e4555;
              color: #fff;
            }
            svg {
              width: 2rem;
              height: 2rem;
            }
          }
        }

        & p {
          margin: 0 0 5px;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 15px;
          line-height: 24px;
          color: #7e4555;
          padding: 0;
        }
        & h5 {
          font-family: "Poppins", sans-serif;
          margin: 0 0 20px;
          line-height: 1.2;
          color: #db4242;
          font-weight: 700;
          font-size: 24px;
          line-height: 1.3;
        }
      }
    }
  }
  .other_info_container {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 1rem 8rem;
    text-align: center;

    .other_info {
      display: flex;
      flex-direction: column;

      justify-content: flex-start;
      width: 40rem;
      text-align: center;
      background-color: #20c4d3;
      border-radius: 20px;
      margin: 0.5rem;
      padding: 0.5rem;
      p {
        margin: 0 0 5px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 24px;
        color: #7e4555;
        padding: 0;
      }
      h5 {
        &::before {
          content: "\2666";
          margin-right: 0.5rem;
        }
        font-family: "Poppins", sans-serif;

        line-height: 1.2;
        color: #db4242;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.3;
      }
    }
  }
}
@media screen and (max-width: 821px) {
  .volunteers-container {
    .sigma_volunteers_01 {
      display: block;
    }
    .info-container {
      display: block;
    }
    .other_info_container {
      flex-direction: column;
    }
  }
}
