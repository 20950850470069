.services_page {
  background-image: url("https://metropolitanhost.com/themes/themeforest/html/maharatri/assets/img/textures/squares.png");
  width: 100%;

  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 11rem 0 8rem;
    margin: 9.9rem;
    .header-title {
      margin-bottom: 3rem;
      p {
        position: relative;
        margin-bottom: 15px;
        font-size: 16px;
        text-transform: uppercase;
        color: #7e4555;
        font-weight: 700;
      }
      h4 {
        position: relative;
        margin: 0;
        font-size: 46px;
        line-height: 1.2;
        margin-bottom: 15px;
        font-weight: 700;
        color: #fff;
      }
    }
    .card-section {
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
      align-items: center;

      align-self: center;
    }
  }
  //css
  a {
    text-decoration: none;
  }
  .flaticon-temple,
  .flaticon-hindu-1,
  .flaticon-pooja {
    img {
      width: 8rem;
    }
  }
  .section {
    position: relative;
    padding: 110px 0;
  }
  .section .container {
    position: relative;
  }

  .section.section-padding {
    padding: 110px 0 80px;
  }
  .section.section-lg {
    padding: 110px 0 200px;
  }
  .section.section-md {
    padding: 110px 0 160px;
  }
  .section {
    position: relative;
    padding: 110px 0;
  }
  .section .container {
    position: relative;
  }

  .section.section-padding {
    padding: 110px 0 80px;
  }
  .section.section-lg {
    padding: 110px 0 200px;
  }
  .section.section-md {
    padding: 110px 0 160px;
  }

  .sigma_section-fw {
    padding: 0 80px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }

  .me-lg-30 {
    margin-left: 30px;
  }

  .section.section-padding.category_section {
    margin-top: -50px;
    padding-top: 0;
  }
  .section-title .badge {
    margin-bottom: 10px;
  }

  .section-title .subtitle {
    position: relative;
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: #7e4555;
    font-weight: 700;
  }
  .section-title.text-start .subtitle {
    justify-content: flex-start;
  }

  .section-title.text-md-right .subtitle,
  .section-title.text-end .subtitle {
    justify-content: flex-end;
  }

  .section-title.text-md-right,
  .section-title.text-end {
    margin: 0 0 50px auto;
  }

  .section-title.text-md-right .subtitle::after,
  .section-title.text-end .subtitle::after,
  .section-title.text-start .subtitle::after {
    display: none;
  }

  .section-title .subtitle::after {
    margin-right: 0;
    margin-left: 15px;
  }

  .section-title p {
    font-size: 16px;
  }

  .section-title.section-title-2 {
    position: relative;
  }
  .section-title.section-title-2 .subtitle {
    color: #7e4555;
    background-color: #e6e8e7;
    display: inline-block;
    padding: 5px 20px;
    font-size: 13px;
  }
  .section-title.section-title-2 .subtitle.light {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .small-text {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 16px;
  }

  .section-title .title {
    position: relative;
    margin: 0;
    font-size: 46px;
    line-height: 1.2;
    margin-bottom: 15px;
    font-weight: 700;
  }

  .section-title {
    position: relative;
    margin-bottom: 50px;
    max-width: 500px;
  }
  .section-title.text-center {
    margin: 0 auto 50px;
  }

  .section-title.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .section-title.flex-title .nav {
    margin-left: auto;
  }

  .section-title.flex-title .nav-item {
    margin: 0;
  }

  .section-title.flex-title .nav-item + .nav-item {
    margin-left: 10px;
  }

  .section-title.flex-title .nav-item .nav-link {
    margin: 0;
  }

  .section-button {
    margin-top: 30px;
  }

  .pattern-squares {
    position: relative;
    z-index: 1;
  }
  .section-title .badge {
    margin-bottom: 10px;
  }

  .section-title .subtitle {
    position: relative;
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: #7e4555;
    font-weight: 700;
  }
  .section-title.text-start .subtitle {
    justify-content: flex-start;
    img {
      width: 4rem;
    }
  }

  .section-title.text-md-right .subtitle,
  .section-title.text-end .subtitle {
    justify-content: flex-end;
  }

  .section-title.text-md-right,
  .section-title.text-end {
    margin: 0 0 50px auto;
  }

  .section-title.text-md-right .subtitle::after,
  .section-title.text-end .subtitle::after,
  .section-title.text-start .subtitle::after {
    display: none;
  }

  .section-title .subtitle::after {
    margin-right: 0;
    margin-left: 15px;
  }

  .section-title p {
    font-size: 16px;
  }

  .section-title.section-title-2 {
    position: relative;
  }
  .section-title.section-title-2 .subtitle {
    color: #7e4555;
    background-color: #e6e8e7;
    display: inline-block;
    padding: 5px 20px;
    font-size: 13px;
  }
  .section-title.section-title-2 .subtitle.light {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .small-text {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 16px;
  }

  .section-title .title {
    position: relative;
    margin: 0;
    font-size: 46px;
    line-height: 1.2;
    margin-bottom: 15px;
    font-weight: 700;
  }

  .section-title {
    position: relative;
    margin-bottom: 50px;
    max-width: 500px;
  }
  .section-title.text-center {
    margin: 0 auto 50px;
  }

  .section-title.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .section-title.flex-title .nav {
    margin-left: auto;
  }

  .section-title.flex-title .nav-item {
    margin: 0;
  }

  .section-title.flex-title .nav-item + .nav-item {
    margin-left: 10px;
  }

  .section-title.flex-title .nav-item .nav-link {
    margin: 0;
  }
  .row {
    margin-left: -5px;
    margin-right: -5px;
    font-size: initial;
  }
  .sigma_service {
    display: block;
    background-color: #fff;
    border: 1px solid #efefef;
    padding: 40px;
    text-align: left;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.3s;
    margin-bottom: 30px;
  }

  .sigma_service.border .sigma_service-thumb {
    position: relative;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin: 0 auto 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #efefef;
  }
  .sigma_service.border .sigma_service-thumb::before {
    content: "";
    position: absolute;
    top: 10px;
    right: 17px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #7e4555;
    transition: 0.3s;
  }
  .sigma_service.border .sigma_service-thumb::after {
    content: "";
    position: absolute;
    bottom: 10px;
    left: 17px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #7e4555;
    transition: 0.3s;
  }

  .sigma_service.primary-bg:hover,
  .sigma_service.primary-bg {
    background-color: #7e4555;
    border: 0;
  }
  .sigma_service.secondary-bg:hover,
  .sigma_service.secondary-bg {
    background-color: #db4242;
    border: 0;
  }

  .sigma_service.border.primary-bg .sigma_service-thumb::after,
  .sigma_service.border.primary-bg .sigma_service-thumb::before,
  .sigma_service.border.secondary-bg .sigma_service-thumb::after,
  .sigma_service.border.secondary-bg .sigma_service-thumb::before {
    background-color: #fff;
  }

  .sigma_service.border:hover .sigma_service-thumb::after {
    bottom: -4px;
    left: 47px;
  }
  .sigma_service.border:hover .sigma_service-thumb::before {
    top: -4px;
    right: 47px;
  }

  .sigma_service.bg-white {
    border: 0;
  }

  .sigma_service.style-1.bg-white:hover .btn-link,
  .sigma_service.style-1.bg-white:hover p,
  .sigma_service.style-1.bg-white:hover h5 {
    color: #db4242;
  }
  .sigma_service.style-1.bg-white:hover i {
    color: #7e4555;
  }

  .sigma_service i {
    font-size: 60px;
    color: #7e4555;
    line-height: 1;
    transition: 0.3s;
  }
  .sigma_service .btn-link {
    margin-top: 20px;
    display: block;
    text-decoration: none;
  }
  .sigma_service .btn-link i {
    font-size: 14px;
    color: #db4242;
    margin-left: 5px;
  }
  .sigma_service-thumb {
    margin-bottom: 40px;
  }
  .sigma_service h5 {
    margin-bottom: 10px;
    transition: 0.3s;

    font-size: 24px;
    line-height: 1.3;
    font-size: 24px;
  }
  .sigma_service p {
    margin: 0;
    transition: 0.3s;
  }
  .sigma_service:hover {
    background-color: #7e4555;
    transform: translateY(-3px);
  }
  .sigma_service.style-1:hover .btn-link,
  .sigma_service.style-1:hover i,
  .sigma_service.style-1:hover p,
  .sigma_service.style-1:hover h5 {
    color: #fff;
  }

  /* Style 2 & 3 */
  .sigma_service.style-3,
  .sigma_service.style-2 {
    background-color: transparent;
    box-shadow: none;
    text-align: left;
    padding: 0;
  }

  .sigma_service.style-3 .sigma_service-thumb,
  .sigma_service.style-2 .sigma_service-thumb {
    position: relative;
    margin: 0;
    z-index: 1;
  }

  .sigma_service.style-3 .sigma_service-thumb img,
  .sigma_service.style-2 .sigma_service-thumb img {
    width: 100%;
  }

  .sigma_service.style-3 i,
  .sigma_service.style-2 i {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -50%);
  }

  .sigma_service.style-3 .sigma_service-thumb::before,
  .sigma_service.style-2 .sigma_service-thumb::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  .sigma_service.style-3:hover i,
  .sigma_service.style-3:hover .sigma_service-thumb::before,
  .sigma_service.style-2:hover i,
  .sigma_service.style-2:hover .sigma_service-thumb::before {
    opacity: 1;
    visibility: visible;
  }

  .sigma_service.style-3 h5,
  .sigma_service.style-2 h5 {
    margin-bottom: 20px;
  }

  .sigma_service.style-3 p,
  .sigma_service.style-2 p {
    margin: 0;
  }

  .sigma_service.style-3 .sigma_service-body,
  .sigma_service.style-2 .sigma_service-body {
    padding: 30px;
  }
  .sigma_service.style-3 {
    border: 0;
  }
  .sigma_service.style-3 .sigma_service-body {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
    border: 0;
  }
  .sigma_service.style-3 .sigma_service-body::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background-color: #7e4555;
    transition: 0.3s;
  }
  .sigma_service.style-3:hover .sigma_service-body::before {
    width: 100%;
  }

  .sigma_service.style-2 .sigma_service-progress {
    margin-top: 20px;
  }

  .sigma_service.style-2 .sigma_service-progress .progress-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sigma_service.style-2 .sigma_service-progress .progress-content p {
    color: #db4242;
    font-size: 16px;
    font-weight: 500;
  }

  .sigma_service.style-2 .sigma_service-progress .sigma_progress {
    margin-top: 15px;
  }

  .sigma_service.style-2 .sigma_service-progress .progress {
    height: 8px;
    background-color: #efefef;
  }

  .sigma_service.style-2 .sigma_btn-custom {
    margin-top: 30px;
  }
  .primary-bg {
    background-color: #7e4555;
  }
  .custom-primary {
    color: #7e4555;
  }
  .mt-negative-sm {
    margin-top: -3rem;
  }
  .mt-negative-sm + .mt-negative-sm {
    margin-top: -6rem;
  }
  .third-container {
    .sigma_service-body h5 {
      color: #db4242;
      font-size: initial;
      font-size: 24px;
      line-height: 1.3;
      font-weight: 700;
    }
    p {
      color: grey;
    }
  }
  .btn-link {
    color: #db4242;
  }
  .text-end {
    .btn-link {
      position: relative;
      color: #db4242;
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }
  }
  @media (max-width: 991px) {
    .mt-negative-sm + .mt-negative-sm,
    .mt-negative-sm {
      margin-top: 0;
    }
  }
}
