.map-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  margin-left: 3rem;
  margin-right: 3rem;
  .sigma_map .sigma_contact-info {
    right: auto;
  }
  .sigma_map {
    height: auto;
  }
  .sigma_map iframe {
    height: 300px;
  }
  .sigma_map {
    height: 600px;
    width: 100%;
  }
  .sigma_map .sigma_contact-info {
    left: auto;
    right: 0;
  }
  .sigma_map iframe {
    width: 100%;
    height: 100%;
    border-radius: 25px;
  }
  .sigma_subheader-extras .sigma_map {
    height: 300px;
  }
}
